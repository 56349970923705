<template>
    <el-dialog
        title="计划详情"
        append-to-body
        width="1200px"
        top="100px"
        :visible.sync="show"
        :before-close="handleClose"
    >
        <div class="detail-container">
            <dy-table height="500px" :dataList="dataList" :rowList="rowList" :loading="loading">
                <template #filter>
                    <el-tooltip content="查看更新状态!" placement="top">
                        <el-button @click="refreshData">刷新数据</el-button>
                    </el-tooltip>
                </template>
                <template #status="{ row }">
                    <el-tooltip v-if="row.msg" :content="row.msg" placement="top">
                        <el-tag
                            :type="getType(row.status).type"
                        >{{ getType(row.status).text }}</el-tag>
                    </el-tooltip>
                    <el-tag
                        :type="getType(row.status).type"
                        v-else
                    >{{ getType(row.status).text }}</el-tag>
                </template>
                <template #pageName="{row}">
                 <span>{{row.pageName.pageName}}</span>
                </template>
                <template #createdTime="{ row }">
                    <span>{{ formatTime(row.createdTime, 'yyyy-MM-dd hh:mm:ss') }}</span>
                </template>
                <template #operation="{ row }">
                    <c-button
                        class="button-small"
                        type="danger"
                        :autoConfirm="true"
                        @confirm="(cb) => deleteItem(row.id, cb)"
                    >删除</c-button>
                </template>
            </dy-table>
        </div>
    </el-dialog>
</template>

<script>
import { formatTime } from '@/assets/js/utils'
import { getTemplateDetails, delWXProjectItems } from '@/api/tencent'
export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataList: [],
      loading: false,
      formatTime,
      rowList: [{
        label: '公众号',
        prop: 'channelName'
      }, {
        label: '状态',
        prop: 'status',
        slot: true
      }, {
        label: '广告样式',
        prop: 'projectAdStyle'
      }, {
        label: '推广页',
        prop: 'pageName',
        slot: true
      }, {
        label: '创建时间',
        prop: 'createdTime',
        slot: true
      }, {
        label: '操作',
        action: 'operation'
      }]
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.getDetail()
        }
      }
    }
  },
  methods: {
    getDetail () {
      this.loading = true
      getTemplateDetails({
        templateId: this.id
      }).then(res => {
        res = res.map(item => {
          return {
            ...item.adcreative,
            ...this.restoreJSONArray(item.adcreative),
            ...item.adgroup,
            ...this.restoreJSONArray(item.adgroup),
            ...item.plan,
            projectAdStyle: this.getProjectAdStyle(item)
          }
        })
        this.dataList = res
      }).finally(() => {
        this.loading = false
      })
    },
    getProjectAdStyle (item) {
      const { plan: { putPosition } } = item
      if (putPosition === '朋友圈信息流') {
        return item.adcreative.adStyle
      }
      if (putPosition === '公众平台流量') {
        if (item.plan.putWayValue !== '激励式广告') {
          return item.adcreative.bmCreativeStyle
        } else {
          return item.adcreative.incentiveAdStyle
        }
      }
      return item.adcreative.adStyle || item.adcreative.bmCreativeStyle || item.adcreative.incentiveAdStyle
    },
    deleteItem (id, cb) {
      delWXProjectItems(id).then(() => {
        this.$message.success('删除成功!')
        this.getDetail()
      }).finally(() => {
        cb()
      })
    },
    handleClose () {
      this.$emit('close')
    },
    getType (status) {
      if (status === 1) {
        return {
          type: 'info',
          text: '未上传'
        }
      }
      if (status === 2) {
        return {
          type: 'warning',
          text: '待上传'
        }
      }
      if (status === 3) {
        return {
          type: 'success',
          text: '已上传'
        }
      }
      if (status === 4) {
        return {
          type: 'danger',
          text: '错误'
        }
      }
      return {
        type: 'info',
        text: '未知'
      }
    },
    refreshData () {
      this.getDetail()
    },
    // 还原被JSON格式化数组数据
    restoreJSONArray (val) {
      const obj = _.cloneDeep(val)
      const res = Object.keys(obj).reduce((acc, b, c) => {
        if (/\[(.?)+\]/g.test(obj[b])) {
          acc[b] = JSON.parse(obj[b])
        }
        return acc
      }, {})
      return res
    }
  },
  components: { }
}
</script>
<style lang='scss' scoped>
.detail-container {
    max-height: 700px;
}
/deep/ .el-table__body-wrapper {
    overflow: auto !important;
}
</style>

<template>
  <el-dialog
    :title="isEdit ? '编辑模板' : '新增模板'"
    :visible.sync="show"
    append-to-body
    width="1200px"
    top="20px"
    :close-on-click-modal="false"
    @close="handleCancel"
  >
    <div class="add-template" style="max-height:700px;overflow:auto">
      <step-one
        ref="stepOne"
        :show="show"
        :isEditTemplate="isEditTemplate"
        :curInfo="copyCurInfo"
        @changeStepOneData="(data) => handleChangeData('step-one', data)"
      ></step-one>
      <step-two
        ref="stepTwo"
        :show="show"
        :isEditTemplate="isEditTemplate"
        :curInfo="copyCurInfo"
        @changeStepOneData="(data) => handleChangeData('step-two', data)"
      ></step-two>
      <step-three
        ref="stepThree"
        :show="show"
        :curInfo="copyCurInfo"
        :isEditTemplate="isEditTemplate"
        :stepOneData="stepOneData"
        @changeStepOneData="(data) => handleChangeData('step-three', data)"
      ></step-three>
    </div>
    <el-backtop target=".add-template" style="right:100px;bottom:100px"></el-backtop>
    <div slot="footer" style="text-align:right;margin-left:20px">
      <el-button @click="handleCancel">取消</el-button>
      <c-button @click="(cb) => handleConfirm(cb)" type="primary">确定</c-button>
    </div>
  </el-dialog>
</template>

<script>
import stepOne from './step-one.vue'
import stepTwo from './step-two.vue'
import stepThree from './step-three.vue'
import _ from 'lodash'
import { isValidArray, isFalsy } from '@/assets/js/utils'
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    curInfo: {
      type: Object,
      default: () => ({})
    },
    show: {
      type: Boolean,
      default: false
    },
    isEditTemplate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      stepOneData: {},
      stepTwoData: {},
      stepThreeData: {},
      copyCurInfo: {}
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          if (this.isEditTemplate === true) {
            this.echoPreData()
          }
          if (!this.isEditTemplate) {
            this.copyCurInfo = _.cloneDeep(this.curInfo)
          }
        } else {
          this.resetData()
        }
      }
    }
  },
  methods: {
    handleCancel () {
      this.$emit('close')
    },
    handleConfirm (cb) {
      const stepThreeData = this.$refs.stepThree.getCurrentData()
      const stepOneData = this.$refs.stepOne.getCurrentData()
      const stepTwoData = this.$refs.stepTwo.getCurrentData()
      const flag = this.judgeStatus(stepOneData, stepTwoData, stepThreeData)
      if (flag) {
        this.packageParams({ stepOneData, stepTwoData, stepThreeData }).then(params => {
          this.$emit('createProject', params)
          this.$emit('close')
        }).finally(() => {
          typeof cb === 'function' && cb()
        })
      } else {
        typeof cb === 'function' && cb()
      }
    },
    judgeStatus (stepOneData, stepTwoData, stepThreeData) {
      const { putPosition, putWay, putWayValue } = stepOneData
      if (!putPosition) {
        this.$message.error('请选择"投放位置"!')
        return false
      }
      // "广告"模块判断逻辑

      if (stepTwoData.productLibraryName && !stepTwoData.productName) {
        this.$message.error('请选择商品')
        return false
      }
      if (stepTwoData.putDate === '选择开始和结束日期') {
        if (!stepTwoData.beginDate || !stepTwoData.endDate) {
          this.$message.error('请完善"开始时间"和"结束时间"！')
          return false
        }
      } else {
        if (!stepTwoData.beginDate) {
          this.$message.error('请完善开始时间!')
          return false
        }
      }
      if (!stepTwoData.putTimeSeries === '每日相同时段') {
        if (!stepTwoData.beginDailyPutTime || !stepTwoData.endDailyPutTime) {
          this.$message.error('请完善"每日投放时段"!')
          return false
        }
      }
      if (!stepTwoData.putMode) {
        this.$message.error('请选择"投放模式"!')
        return false
      }

      if (!stepTwoData.optimizationGoal) {
        this.$message.error('请选择"优化目标"！')
        return false
      }
      if (stepTwoData.targetPackageList.some(item => !isValidArray(item.chooseRegionList))) {
        this.$message.error('请完善定向包的地域!')
        return false
      }

      if (stepTwoData.deepConversionTarget === '首日付费ROI') {
        if (!stepTwoData.deepConversionRate) {
          this.$message.error('请输入“ROI目标值”!')
          return false
        }
      }

      if (!stepTwoData.adgroupDailyBudget) {
        this.$message.error('每日预算不能为空！')
        return false
      }


      if (stepTwoData.keyVolume) {
        if (!stepTwoData.exploreBudget) {
          this.$message.error('请输入"探索预算"！')
          return false
        }
      }

      if (putPosition === '朋友圈信息流') {
        const { adStyle } = stepThreeData
        const { outerCopyList, pageNameList, textLinkCopy } = stepThreeData
        if (pageNameList.length === 0) {
          this.$message.error('请选择原生推广页')
          return false
        }
        if (outerCopyList.some(item => item.textLen > 40 || item.emojiLen.length > 4)) {
          this.$message.error('文案字数超出了限制!')
          return false
        }
        if (adStyle === '常规广告') {
          if (outerCopyList.some(item => !item.value)) {
            this.$message.error('请完善外层广告文案!')
            return false
          }
          if (!textLinkCopy) {
            this.$message.error('请选择“文字链文案”!')
            return false
          }
        }

        if (adStyle === '卡片式广告') {
          const { outerCopyTitleList, materialList, cardComponent, chooseTag, buttonCopy } = stepThreeData
          if (outerCopyList.length !== outerCopyTitleList.length) {
            this.$message.error('"外层文案标题"数量与"外层文案"数量不相等!')
            return false
          }
          // ! 判断文本框的内容是否为空的情况
          if (outerCopyList.some(item => !item.value) || outerCopyTitleList.some(item => !item.value)) {
            this.$message.error('文本框的内容不能为空!')
            return false
          }
          if (outerCopyTitleList.some(item => item.textLen > 10 || item.emojiLen > 1)) {
            this.$message.error('文本标题不符合要求!')
            return false
          }

          if (!isValidArray(materialList) || materialList.length !== pageNameList.length) {
            this.$message.error('请完善外层图片!')
            return false
          }

          // ! 判断卡片组件下面的勾选项
          if (cardComponent.includes('标签')) {
            if (!isValidArray(chooseTag)) {
              this.$message.error('标签不能为空!')
              return false
            }
          }
          if (cardComponent.includes('行动按钮')) {
            if (!buttonCopy) {
              this.$message.error('请选择"按钮文案"')
              return false
            }
          }
        }
      }

      if (putPosition === '公众平台流量') {
        const { bmCreativeStyle, guidingCopyList, adCardCopyList, materialList, bmInformationBarType, incentiveCreativeStyle, incentiveAdStyle, msgBoardCopyList, pageNameList, bmiButtonCopy, bmiTagShow, bmChooseTag } = stepThreeData
        if (!putWay) {
          this.$message.error('请点击“广告位投放”')
          return false
        }
        if (putWayValue === '公众号文章底部') {
          if (!isValidArray(materialList) && bmCreativeStyle !== '名片式banner') {
            this.$message.error('请上传相关素材!')
            return false
          }
          if (msgBoardCopyList.some(item => isFalsy(item)) && bmInformationBarType === '文案型' && bmCreativeStyle === '大图式卡片') {
            this.$message.error('请完善信息栏文案!')
            return false
          }
          if (!isValidArray(pageNameList)) {
            this.$message.error('请选择原生推广页!')
            return false
          }
          if (bmCreativeStyle === '名片式banner') {
            if (!isValidArray(guidingCopyList) || guidingCopyList.some(item => isFalsy(item))) {
              this.$message.error('“引导文案”不能为空!')
              return false
            }
          }
          if (bmiTagShow === '行业标签' && bmCreativeStyle === '大图式卡片') {
            if (!isValidArray(bmChooseTag)) {
              this.$message.error('请选择标签!')
              return false
            }
          }
          if (!bmiButtonCopy) {
            this.$message.error('请选择按钮文案!')
            return false
          }
        }
        if (putWayValue === '公众号文章中部') {
          if (msgBoardCopyList.some(item => isFalsy(item)) && bmInformationBarType === '文案型') {
            this.$message.error('请完善信息栏文案!')
            return false
          }
          if (bmiTagShow === '行业标签') {
            if (!isValidArray(bmChooseTag)) {
              this.$message.error('请选择标签!')
              return false
            }
          }
          if (!isValidArray(pageNameList)) {
            this.$message.error('请选择原生推广页!')
            return false
          }
          if (!bmiButtonCopy) {
            this.$message.error('请选择按钮文案!')
            return false
          }
        }
        if (putWayValue === '激励式广告') {
          if (!incentiveAdStyle) {
            this.$message.error('请选择广告样式!')
            return false
          }
          if (incentiveAdStyle === '激励视频' && !incentiveCreativeStyle) {
            this.$message.error('请选择创意样式!')
            return false
          }
          if (incentiveAdStyle === '激励视频' && !isValidArray(materialList)) {
            this.$message.error('请上传视频素材!')
            return false
          }
          if (!isValidArray(pageNameList)) {
            this.$message.error('请选择原生推广页!')
            return false
          }
          if (incentiveAdStyle === '激励视频' && !bmiButtonCopy) {
            this.$message.error('请选择按钮文案!')
            return false
          }
          if (incentiveAdStyle === '激励视频' && !bmiTagShow) {
            this.$message.error('请选择"标签展示"!')
            return false
          }
          if (incentiveAdStyle === '激励视频' && !isValidArray(adCardCopyList)) {
            this.$message.error('请输入“广告卡片文案”!')
            return false
          }
        }
      }
      return true
    },
    packageParams ({ stepOneData, stepTwoData, stepThreeData }) {
      return new Promise(resolve => {
        let arr = []
        const { putPosition, putWayValue } = stepOneData
        const { targetPackageList } = stepTwoData
        // ! 所有模式都需要定向包进行循环创建计划数据
        for (const pack of targetPackageList) {
          if (putPosition === '朋友圈信息流') {
            const { adStyle } = stepThreeData
            if (adStyle === '常规广告') {
              const { outerCopyList, pageNameList } = stepThreeData

              for (const oCopy of outerCopyList) {
                for (const page of pageNameList) {
                  arr = [...arr, {
                    plan: {
                      ...stepOneData
                    },
                    adgroup: {
                      ...stepTwoData,
                      ...pack,
                      // targetPackage:
                      targetPack: pack
                    },
                    adcreative: {
                      ...stepThreeData,
                      outerCopy: oCopy,
                      pageName: page
                    }
                  }]
                }
              }
            }
            if (adStyle === '卡片式广告') {
              const { outerCopyList, outerCopyTitleList, pageNameList, materialList } = stepThreeData
              for (let i = 0; i < outerCopyList.length; i++) {
                const outerCopy = outerCopyList[i]
                const outerCopyTitle = outerCopyTitleList[i]
                for (let j = 0; j < pageNameList.length; j++) {
                  const page = pageNameList[j]
                  arr = [...arr, {
                    plan: {
                      ...stepOneData
                    },
                    adgroup: {
                      ...stepTwoData,
                      ...pack,
                      targetPack: pack
                    },
                    adcreative: {
                      ...stepThreeData,
                      pageName: page,
                      outerCopy: outerCopy,
                      outerCopyTitle: outerCopyTitle,
                      materialType: materialList[j].type,
                      materialResourceId: materialList[j].id
                    }
                  }]
                }
              }
            }
          }
          if (putPosition === '公众平台流量') {
            if (putWayValue === '公众号文章底部') {
              const { pageNameList, materialList, msgBoardCopyList, guidingCopyList, bmCreativeStyle } = stepThreeData
              for (const page of pageNameList) {
                if (bmCreativeStyle === '大图式卡片') {
                  for (const msg of msgBoardCopyList) {
                    arr = [...arr, {
                      plan: {
                        ...stepOneData
                      },
                      adgroup: {
                        ...stepTwoData,
                        ...pack,
                        targetPack: pack
                      },
                      adcreative: {
                        ...stepThreeData,
                        pageName: page,
                        bmMsgBoardCopy: msg,
                        materialType: materialList[0].type,
                        materialResourceId: materialList[0].id
                      }
                    }]
                  }
                }
                if (bmCreativeStyle === '图片式卡片') {
                  arr = [...arr, {
                    plan: {
                      ...stepOneData
                    },
                    adgroup: {
                      ...stepTwoData,
                      ...pack,
                      targetPack: pack
                    },
                    adcreative: {
                      ...stepThreeData,
                      pageName: page,
                      materialType: materialList[0].type,
                      materialResourceId: materialList[0].id
                    }
                  }]
                }
                if (bmCreativeStyle === '名片式banner') {
                  for (const guide of guidingCopyList) {
                    arr = [...arr, {
                      plan: {
                        ...stepOneData
                      },
                      adgroup: {
                        ...stepTwoData,
                        ...pack,
                        targetPack: pack

                      },
                      adcreative: {
                        ...stepThreeData,
                        pageName: page,
                        bGuidingCopy: guide
                      }
                    }]
                  }
                }
              }
            }
            if (putWayValue === '公众号文章中部') {
              const { pageNameList, materialList, msgBoardCopyList } = stepThreeData
              for (const page of pageNameList) {
                for (const msg of msgBoardCopyList) {
                  arr = [...arr, {
                    plan: {
                      ...stepOneData
                    },
                    adgroup: {
                      ...stepTwoData,
                      ...pack,
                      targetPack: pack

                    },
                    adcreative: {
                      ...stepThreeData,
                      pageName: page,
                      bmMsgBoardCopy: msg,
                      materialType: materialList[0].type,
                      materialResourceId: materialList[0].id
                    }
                  }]
                }
              }
            }
            if (putWayValue === '激励式广告') {
              const { pageNameList, adCardCopyList, materialList, incentiveAdStyle } = stepThreeData
              if (incentiveAdStyle === '激励视频') {
                for (const adCardCopy of adCardCopyList) {
                  for (const page of pageNameList) {
                    arr = [...arr, {
                      plan: {
                        ...stepOneData
                      },
                      adgroup: {
                        ...stepTwoData,
                        ...pack,
                        targetPack: pack
                      },
                      adcreative: {
                        ...stepThreeData,
                        pageName: page,
                        incentiveAdCardCopy: adCardCopy,
                        materialType: materialList[0].type,
                        materialResourceId: materialList[0].id
                      }
                    }]
                  }
                }
              }
              if (incentiveAdStyle === '激励浏览') {
                for (const page of pageNameList) {
                  arr = [...arr, {
                    plan: {
                      ...stepOneData
                    },
                    adgroup: {
                      ...stepTwoData,
                      ...pack,
                      targetPack: pack

                    },
                    adcreative: {
                      ...stepThreeData,
                      pageName: page,
                    }
                  }]
                }
              }
            }
          }
        }
        return resolve(arr)
      })
    },
    handleChangeData (type, data) {
      if (type === 'step-one') {
        this.stepOneData = _.cloneDeep(data)
      }
      if (type === 'step-two') {
        this.stepTwoData = _.cloneDeep(data)
      }
      if (type === 'step-three') {
        this.stepThreeData = _.cloneDeep(data)
      }
    },
    delProperty (obj, propertyList) {
      if (!propertyList) return obj
      let list = []
      if (!Array.isArray(propertyList)) {
        list = [propertyList]
      }
      const copyObj = _.cloneDeep(obj)
      list = propertyList
      for (const p of list) {
        delete copyObj[p]
      }
      return obj
    },
    echoPreData () {
      if (this.isEditTemplate === true) {
        // ! 根据编辑列表数据进行模板数据回显
        const { adcreative, adgroup, plan } = this.curInfo.list[0]
        this.copyCurInfo = {
          marketing: this.curInfo.marketing,
          principalPart: this.curInfo.principalPart,
          ...{ ...adcreative, ...adgroup, ...plan }
        }
      }
    },
    resetData () {
      this.$refs.stepOne.resetData()
      this.$refs.stepTwo.resetData()
      this.$refs.stepThree.resetData()
    }
  },
  components: { stepOne, stepTwo, stepThree }
}
</script>
<style lang='scss' scoped>
</style>

<template>
  <el-dialog
    title="新增计划模板"
    width="800px"
    append-to-body
    top="20px"
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="handleCancel"
  >
    <div class="add-plan" v-loading="containerLoading">
      <div class="operation">
        <h3>选择账号：</h3>
        <el-button type="primary" @click="handleChooseAccount">选择账号</el-button>
      </div>
      <div class="account-list" v-if="chooseAccountList.length > 0">
        <el-tabs type="card" style="max-height:600px" editable @tab-remove="removeTabs">
          <el-tab-pane
            v-for="(account, index) in chooseAccountList"
            :key="index"
            :label="account[0]"
            :name="`${index}`"
          >
            <div class="account-item" v-for="(item, subIndex) in account[1]" :key="item.targetId">
              <div class="account-info">
                <div class="account-info-title">{{ item.channelName }}</div>
                <div v-if="item.principalPart">主体：{{ item.principalPart.accountName }}</div>
                <div>
                  <el-button
                    type="warning"
                    class="button-small"
                    @click="addTemplate(item, index, subIndex)"
                  >创建计划模板</el-button>
                  <el-button
                    v-if="isValidArray(item.list)"
                    class="button-small"
                    style="margin-left:15px"
                    @click="editCurTemplate(item, index, subIndex)"
                  >修改当前模板</el-button>
                </div>
              </div>
              <div class="summary-info" v-if="isValidArray(item.list)">
                <div>
                  <span>
                    <span>推广目标：</span>
                    <span>{{ item.list[0].plan.promotedObject }}</span>
                  </span>
                  <span>
                    <span>投放位置：</span>
                    <span>{{ item.list[0].plan.putPosition }}</span>
                  </span>
                  <span v-if="item.list[0].plan.putWay">
                    <span>投放方式：</span>
                    <span>{{ item.list[0].plan.putWay }}</span>
                  </span>
                </div>
              </div>
              <i class="el-icon-delete-solid delete-icon" @click="handleSpliceAccount(account[1],subIndex)"></i>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
          <div class="empty-text" v-else style="height:500px">
          暂无数据...
    </div>

      <div slot="footer" style="text-align: right;margin-right:20px">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="handleSave" :loading="saveLoading">确定</el-button>
      </div>
    </div>
    <el-dialog
      title="选择账号"
      width="750px"
      append-to-body
      top="20px"
      :visible.sync="showAccountDialog"
      :close-on-click-modal="false"
      @close="handleCloseDialog"
    >
      <dy-table
        :dataList="accountList"
        :rowList="rowList"
        height="500px"
        ref="AccTable"
        refName="subTable"
        :showPagination="true"
        :isUseCheckbox="true"
        :pageSize="pageSize"
        :page="page"
        :total="total"
        :loading="loading"
        @pageChange="handlePage"
        @selection-change="handleSelectionChange"
        @row-click="handleRowClick"
      >
        <template #filter>
          <div style="line-height:50px">
            <c-input
              v-model="sendForm.channelName"
              placeholder="账号名称"
              style="width:200px;margin-right:10px"
            ></c-input>
            <c-input
              v-model="sendForm.appId"
              placeholder="appid"
              style="width:200px;margin-right:10px"
            ></c-input>
            <c-input
              v-model="sendForm.accountId"
              placeholder="账号Id"
              style="width:200px;margin-right:10px"
            ></c-input>
            <el-button @click="handlePage(1)" type="primary">搜索</el-button>
          </div>
          <div class="operations">
            <div class="principal-info">
              <span v-if="choiceList.length > 0">
                <span style="font-weight:bold">勾选账号的主体为：</span>
                <span
                  v-if="principalValue"
                >{{ isValidArray(principalList) ? principalList.find(item => item.id === principalValue).accountName : '' }}</span>
              </span>
            </div>
            <div class="required">
              <el-select
                v-model="principalValue"
                @change="handleSelectChange"
                placeholder="选择主体"
                style="width:200px"
                filterable
              >
                <el-option
                  v-for="item in principalList"
                  :key="item.id"
                  :label="item.accountName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </template>
        <template #channelName="{ row }">
          <span>{{ row.channelName }}{{ `${row.principalPart ? `（${row.principalPart.accountName}）` : ''}` }}</span>
        </template>
      </dy-table>
      <div slot="footer" class="button-list">
        <el-button type="primary" @click="handleOk">确定</el-button>
        <el-button @click="handleCloseDialog">取消</el-button>
      </div>
    </el-dialog>
    <add-template
      ref="addTemplateRef"
      @close="showAddTemplate = false"
      @createProject="createProject"
      :isEditTemplate="isEditTemplate"
      :isEdit="isEdit"
      :curInfo="curInfo"
      :show="showAddTemplate"
    ></add-template>
  </el-dialog>
</template>

<script>
import { nanoid } from 'nanoid'
import { isValidArray, isValidObject, deepFlatten, deduplicationProperty, chunk, isFalsy } from '@/assets/js/utils'
import { getSubject, getWxAccountList, addWxPlan, editWxPlan } from '@/api/tencent.js'

import AddTemplate from './children/add-template.vue'
import _ from 'lodash'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    curTemplateInfo: {
      type: Object,
      default: () => ({})
    },
    curTemplateId: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      copyData: {},
      page: 1,
      total: 0,
      curInfo: {},
      pageSize: 15,
      sendForm: {},
      isValidArray,
      selection: [],
      isValidObject,
      loading: false,
      choiceList: [],
      accountList: [],
      principalList: [],
      preSelectList: [], // 选择账号列表，上一次的选中状态
      curSelectList: [], // 选择账号列表，当前的选中状态
      principalValue: '',
      saveLoading: false,
      isRenderAuto: true, // ! 是否是回显自动勾选表格行
      curInputIndex: null,
      isEditTemplate: false,
      showAddTemplate: false,
      chooseAccountList: [],
      containerLoading: false,
      showAccountDialog: false,
      curMainIndex: null,
      curSubIndex: null,
      rowList: [{
        label: 'id',
        prop: 'id',
        colWidth: '100'
      }, {
        label: '公众号',
        prop: 'channelName'
      }, {
        label: 'appid',
        prop: 'appid',
        colWidth: '200'
      }]
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV === true) {
          this.setChoiceList()
          this.setEnterEvent()
          this.renderData()
          if (this.isEdit === true) {
            this.echoData()
          }
        } else {
          this.chooseAccountList = []
          this.resetData()
        }
      }
    }
  },
  methods: {
    renderData () {
      getSubject({
        page: 1,
        pageSize: 9999
      }).then(res => {
        this.principalList = res.list
      })
    },
    echoData () {
      this.chooseAccountList = Object.entries(this.curTemplateInfo.template_plans.reduce((acc, b, c) => {
        (acc[b.subject.accountName] || (acc[b.subject.accountName] = [])).push({
          channelName: b.account.channelName,
          id: b.account.id,
          marketing: b.account.marketing,
          principalPart: {
            id: b.subject.id,
            type: b.subject.type,
            accountName: b.subject.accountName
          },
          list: this.packAgeList(b)
        })
        return acc
      }, {}))
    },
    packAgeList (val) {
      const { mpPlans, customList } = val
      const arr = []
      for (const item of mpPlans) {
        const { adcreative, adgroup, plan } = item
        arr.push({
          // ! 直接把customList里面的所有字段穿进去，省去判断创意部分广告方式的逻辑
          adcreative: {
            ...adcreative,
            ...this.restoreJSONArray(customList),
            ...this.restoreJSONArray(adcreative)
          },
          adgroup: {
            ...adgroup,
            ...this.restoreJSONArray(adgroup)
          },
          plan
        })
      }
      return arr
    },
    setEnterEvent () {
      document.onkeydown = (e) => {
        if (e.key === 'Enter' && e.keyCode === 13) {
          const keys = Object.keys(this.sendForm)
          if (keys.length > 0 && keys.some(key => !isFalsy(this.sendForm[key]))) {
            this.handlePage(1)
          }
        }
      }
    },
    setChoiceList () {
      // ! 根据不同主体回显不同的主体下的账号列表
      if (isValidArray(this.chooseAccountList)) {
        const targetValue = this.principalList.find(item => item.id === this.principalValue)
        if (targetValue) {
          const { accountName } = targetValue
          const array = this.chooseAccountList.find(item => item[0] === accountName)
          this.choiceList = array ? array[1] : []
        } else {
          this.choiceList = []
        }
      }
    },
    // 还原被JSON格式化数组数据
    restoreJSONArray (val) {
      const obj = _.cloneDeep(val)
      return Object.keys(obj).reduce((acc, b, c) => {
        if (/\[(.?)+\]/g.test(obj[b])) {
          acc[b] = JSON.parse(obj[b])
        }
        return acc
      }, {})
    },
    handleSave () {
      if (this.chooseAccountList.length === 0 || this.chooseAccountList.some(item => !isValidArray(item[1]))) {
        return this.$message.warning('单个主体下所选账号不能为空！')
      }
      if (this.chooseAccountList.some(item => item[1].some(val => !isValidArray(val.list)))) {
        return this.$message.warning('请完善每个账号下的计划模板!')
      }

      this.saveLoading = true
      const list = deepFlatten(this.chooseAccountList.map(item => item[1].map(account => account.list)))
      this.$prompt('请输入模板名称!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.isEdit ? this.curTemplateInfo.template_name : ''
      }).then(({ value }) => {
        if (this.isEdit) {
          if (value !== this.curTemplateInfo.template_name) {
            addWxPlan({
              content: {
                template_name: value,
                template_plans: list
              }
            }).then(() => {
              this.$message.success('新增成功!')
              this.handleCancel()
              this.$emit('refreshData')
            }).finally(() => {
              this.saveLoading = false
            })
          } else {
            editWxPlan(this.curTemplateId, {
              content: {
                template_name: value,
                template_plans: list
              }
            }).then(() => {
              this.$message.success('编辑成功!')
              this.handleCancel()
              this.$emit('refreshData')
            }).finally(() => {
              this.saveLoading = false
            })
          }
        } else {
          addWxPlan({
            content: {
              template_name: value,
              template_plans: list
            }
          }).then(() => {
            this.$message.success('新增成功!')
            this.handleCancel()
            this.$emit('refreshData')
          }).finally(() => {
            this.saveLoading = false
          })
        }
      }).catch(() => {
        this.saveLoading = false
      })
    },
    handleCancel () {
      this.$emit('close')
    },
    handleChooseAccount () {
      this.showAccountDialog = true
      this.loading = true
      this.isRenderAuto = true
      const printObj = this.principalList.find(item => item.id === this.principalValue)
      getWxAccountList({
        page: this.page,
        pageSize: this.pageSize,
        ...this.sendForm
      }).then(res => {
        this.accountList = res.list.map(item => ({
          ...item,
          principalPart: this.choiceList.find(select => select.id === item.id) ? printObj || null : null
        }))
        this.total = res.total
      }).finally(() => {
        this.loading = false
        this.$nextTick(async () => {
          await this.echoChecked()
          this.preSelectList = _.cloneDeep(this.$refs.AccTable.$refs.subTable.selection)
          this.isRenderAuto = false
        })
      })
    },
    addTemplate (val, index, subIndex) {
      this.curInfo = val
      this.curMainIndex = index
      this.curSubIndex = subIndex
      this.isEditTemplate = false
      this.showAddTemplate = true
    },
    editCurTemplate (val, index, subIndex) {
      this.curInfo = val
      this.curMainIndex = index
      this.curSubIndex = subIndex
      this.isEditTemplate = true
      this.showAddTemplate = true
    },
    removeTabs (tabIndex) {
      this.chooseAccountList.splice(+tabIndex, 1)
      this.choiceList = []
    },
    handlePage (page) {
      this.page = page
      this.preSelectList = []
      this.handleChooseAccount()
    },
    handleCloseDialog () {
      this.showAccountDialog = false
    },
    handleOk () {
      if (this.choiceList.length === 0) {
        return this.$message.warning('所选账号不能为空!')
      }
      if (!this.principalValue) {
        return this.$message.warning('请选择主体')
      }
      // ! 第一步保留原来的旧数据，防止新数据id相同，但是数据不相同的问题
      let array = _.cloneDeep(this.choiceList).map(item => {
        return {
          ...item,
          targetId: item.targetId || nanoid(),
          principalPart: this.principalList.find(item => item.id === this.principalValue)
        }
      })
      let preArrayList = isValidArray(this.chooseAccountList) ? this.chooseAccountList : []
      if (preArrayList.length > 0) {
        const keyValue = this.principalList.find(item => item.id === this.principalValue).accountName
        const keyIndex = preArrayList.findIndex(item => item[0] === keyValue)
        if (preArrayList[keyIndex]) {
          const needClearItems = preArrayList[keyIndex][1].reduce((acc, b, c) => {
            // ! 如果在preArrayList里面找不到array里面的数据，则表示在这次操作当中，原来勾选的主体账号这次取消了
            if (!array.find(item => item.id === b.id)) {
              acc.push(b)
            }
            return acc
          }, [])

          const list = JSON.parse(JSON.stringify(preArrayList[keyIndex][1]))
          needClearItems.forEach(item => {
            const index = list.findIndex(val => val.id === item.id)
            list.splice(index, 1)
          })
          preArrayList[keyIndex][1] = JSON.parse(JSON.stringify(list))
        }
        preArrayList = deepFlatten(preArrayList.map(item => item[1]))
        // * 把原来的数据放在第一部分，在下面去重的时候可以保留原来的数据
        array = [...preArrayList, ...array]
      }
      const obj = {}
      array = [...Object.entries(array.reduce((acc, b, c) => {
        if (!obj[b.principalPart.accountName]) {
          obj[b.principalPart.accountName] = []
        }
        obj[b.principalPart.accountName].push(b)
        return obj
      }, {}))]
      // !对array进行去重 还存在原有数据消失的问题(但去重)

      this.chooseAccountList = array.map(item => {
        return [item[0], deduplicationProperty(item[1], 'id')]
      })
      this.handleCloseDialog()
    },
    handleSelectionChange (select) {
      this.$nextTick(() => {
        if (this.isRenderAuto) return
        if (!isValidArray(this.preSelectList)) {
          this.preSelectList = _.cloneDeep(select)
        }
        this.curSelectList = _.cloneDeep(select)
        select.forEach((item, index) => {
          const targetIndex = this.choiceList.findIndex(i => i.id === item.id)
          if (targetIndex === -1) {
            this.choiceList = [...this.choiceList, item]
          }
        })
        // ! 如果在当前页之前的某一样被选中，之后取消选中,则要保存的数组当中取消
        // todo=>暂时有一个bug，如果全选当前页，然后取消全选，然后再勾选一个账号，就会触发bug
        // * 需要考虑全选的操作
        const needSpliceItem = this.preSelectList.filter(preItem => {
          return !this.curSelectList.find(curItem => curItem.id === preItem.id)
        })
        if (isValidArray(needSpliceItem)) {
          for (const item of needSpliceItem) {
            const Index = this.choiceList.findIndex(account => account.id === item.id)
            if (Index > -1) {
              this.choiceList.splice(Index, 1)
            }
          }
        }
        this.preSelectList = _.cloneDeep(select)
      })
    },
    echoChecked () {
      return new Promise(resolve => {
        if (this.chooseAccountList.length > 0) {
          this.principalValue || (this.principalValue = this.principalList[0].id)
          const targetAccountList = this.chooseAccountList.find(item => item[0] === this.principalList.find(item => item.id === this.principalValue).accountName)
          if (targetAccountList) {
            for (const account of targetAccountList[1]) {
              const targetItem = this.accountList.find(item => item.id === account.id)
              if (targetItem) {
                this.$refs.AccTable.$refs.subTable.toggleRowSelection(
                  targetItem,
                  true
                )
              }
            }
          }
        }
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    checkSelectionList () {
      return new Promise(resolve => {
        if (this.choiceList.length > 0) {
          this.accountList.forEach(account => {
            const targetItem = this.choiceList.find(item => item.id === account.id)
            this.$refs.AccTable.$refs.subTable.toggleRowSelection(account, !!targetItem)
          })
        }
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    async handleSelectChange (value) {
      this.principalValue = value
      this.setChoiceList()
      if (this.choiceList.length > 0) {
        await this.checkSelectionList()
      } else {
        this.accountList.forEach(account => {
          this.$refs.AccTable.$refs.subTable.toggleRowSelection(account, false)
        })
      }
    },
    handleRowClick (row) {
      return this.$refs.AccTable.$refs.subTable.toggleRowSelection(row)
    },
    createProject (data) {
      this.$set(this.chooseAccountList[this.curMainIndex][1][this.curSubIndex], 'list', data)
    },
    handleSpliceAccount (accountList, subIndex) {
      const targetIndex = this.choiceList.findIndex(item => item.id === accountList[subIndex].id)
      this.choiceList.splice(targetIndex,1)
      accountList.splice(subIndex, 1)
    },
    resetData () {
      this.curInfo = {}
      this.sendForm = {}
      this.choiceList = []
      this.principalValue = ''
      this.preSelectList = []
      this.curSelectList = []
      this.isRenderAuto = true
      this.chooseAccountList = []
    }
  },
  components: { AddTemplate }
}
</script>
<style lang='scss' scoped>
.account-list {
  max-height: 700px;
  margin-top: 20px;
  overflow: auto;
  .account-item {
    @include flex(space-between, flex-start);
    position: relative;
    margin-bottom:20px;
    border-bottom:1px solid #ececec;
    .account-info {
      @include flex(flex-start, flex-start, column);
      flex-shrink: 0;
      height: 300px;
      // margin-bottom: 20px;
      // margin-left: 50px;
      margin: 0 20px 20px 50px;
      > div {
        margin-bottom: 10px;
      }
      > span:nth-of-type(1) {
        margin-bottom: 5px;
      }
    }
    .delete-icon{
      position: absolute;
      left:0%;
      top:-2%;
      font-size:18px;
      cursor: pointer;
      display:none;
      color:$colorRed;
    }
    &:hover{
      .delete-icon{
        display: block;
      }
    }
  }
}
.empty-text{
  @include flex(center,center)
}
.operation {
  @include flex(flex-start, center);
}
.operations {
  @include flex(space-between, center);
}
.summary-info {
  margin-right: 40px;
  > div {
    @include flex(flex-start, flex-start, column) >span {
      margin-bottom: 10px;
    }
  }
}
</style>

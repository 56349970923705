var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"计划详情","append-to-body":"","width":"1200px","top":"100px","visible":_vm.show,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{staticClass:"detail-container"},[_c('dy-table',{attrs:{"height":"500px","dataList":_vm.dataList,"rowList":_vm.rowList,"loading":_vm.loading},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('el-tooltip',{attrs:{"content":"查看更新状态!","placement":"top"}},[_c('el-button',{on:{"click":_vm.refreshData}},[_vm._v("刷新数据")])],1)]},proxy:true},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.msg)?_c('el-tooltip',{attrs:{"content":row.msg,"placement":"top"}},[_c('el-tag',{attrs:{"type":_vm.getType(row.status).type}},[_vm._v(_vm._s(_vm.getType(row.status).text))])],1):_c('el-tag',{attrs:{"type":_vm.getType(row.status).type}},[_vm._v(_vm._s(_vm.getType(row.status).text))])]}},{key:"pageName",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.pageName.pageName))])]}},{key:"createdTime",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formatTime(row.createdTime, 'yyyy-MM-dd hh:mm:ss')))])]}},{key:"operation",fn:function(ref){
var row = ref.row;
return [_c('c-button',{staticClass:"button-small",attrs:{"type":"danger","autoConfirm":true},on:{"confirm":function (cb) { return _vm.deleteItem(row.id, cb); }}},[_vm._v("删除")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div id="step-one">
        <h2 style="font-size:18px">投放计划</h2>
        <div class="container">
            <div class="part">
              <span>计划标题
                <el-tooltip content="如果不填，将以微信的默认标题为准" placement="top">
                    <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
                <c-input v-model="copyData.planTitle" style="width:300px"></c-input>
            </div>
            <div class="part">
                <span>推广目标</span>
                <el-button class="button-small" type="primary">推广公众号</el-button>
            </div>
            <div class="part">
                <span>投放位置</span>
                <el-radio-group v-model="copyData.putPosition">
                    <el-radio label="朋友圈信息流"></el-radio>
                      <el-radio label="公众平台流量"></el-radio>
                </el-radio-group>
            </div>
            <div class="part" v-if="copyData.putPosition === '公众平台流量'">
                <el-radio-group style="margin-left:220px;" size="small" v-model="copyData.putWay">
                    <el-radio-button label="按广告位投放"></el-radio-button>
                </el-radio-group>
                <el-radio-group
                    style="margin-left:20px"
                    v-model="copyData.putWayValue"
                    v-if="copyData.putWay === '按广告位投放'"
                >
                    <el-radio label="公众号文章底部"></el-radio>
                    <el-radio label="公众号文章中部"></el-radio>
                    <el-radio label="激励式广告"></el-radio>
                </el-radio-group>
            </div>
            <div class="part">
                <span>购买方式</span>
                <span class="fixed-text">竞价购买广告</span>
            </div>
            <div
                class="part"
                v-if="copyData.putWayValue === '公众号文章底部' && copyData.putPosition === '公众平台流量'"
            >
                <span>选择素材格式</span>
                <span class="fixed-text">图片</span>
            </div>
            <div class="part">
                <span>计划日预算(选填)</span>
                <c-input v-model="copyData.dailyBudget" :onlyNum="true" style="width:200px" />
                <span style="margin-left:5px">元/天</span>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    curInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      copyData: {},
      initializeData: {
        // putPosition: '朋友圈信息流',
        putPosition: '',
        putWay: '按广告位投放',
        putWayValue: '',
        dailyBudget: '',
        materialFormat:'图片',
        planTitle:'',
      }
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV === true) {
          this.copyData = Object.assign(this.initializeData,_.cloneDeep(this.curInfo))
        }
      },
      immediate: true
    },
    copyData: {
      handler (newV) {
        this.$emit('changeStepOneData', newV)
      },
      deep: true
    }
  },
  methods: {
    getCurrentData () {
      return _.cloneDeep({
        channelMarketingId: this.copyData.marketing.id,
        type: this.copyData.principalPart.type,
        promotedObject: '推广公众号',
        putPosition: this.copyData.putPosition,
        putWay: this.copyData.putPosition === '朋友圈信息流' ? '' : this.copyData.putWay,
        putWayValue: this.copyData.putPosition === '朋友圈信息流' ? '' : this.copyData.putWayValue,
        purchaseWay: '竞价购买',
        purchaseWayValue: '竞价购买广告',
        dailyBudget: this.copyData.dailyBudget,
        materialFormat: this.copyData.putPosition === '公众平台流量' ? this.copyData.materialFormat : '',
        planTitle:this.copyData.planTitle
      })
    },
    resetData () {
      this.initializeData = {
        putPosition: '',
        putWay: '按广告位投放',
        putWayValue: '',
        dailyBudget: '',
        planTitle:'',
      }
      this.copyData = {}
    }
  }
}
</script>
<style lang='scss' scoped>
.part {
    @include flex(flex-start, center);
    margin: 30px 0;
    > span:nth-of-type(1) {
        width: 200px;
        flex-shrink: 0;
        text-align: right;
        margin-right: 20px;
    }
}
.fixed-text {
    color: $main;
    font-weight: bold;
}
</style>

<template>
    <div id="step-two">
        <h2 style="font-size:18px">广告</h2>
        <div class="container">
            <div class="part">
                <span>商品库</span>
                <el-button
                    v-if="!copyData.productLibraryName"
                    type="success"
                    @click="chooseProductLibrary"
                    class="button-small"
                >选择商品库</el-button>
                <span v-else>
                    {{ copyData.productLibraryName }}
                    <span
                        style="cursor:pointer;color:#00bf8a;margin-left:5px"
                        @click="chooseProductLibrary"
                    >更改</span>
                </span>
            </div>
            <div class="part">
                <span>商品</span>
                <span v-if="copyData.productName">
                    {{ copyData.productName }}
                    <span
                        style="cursor:pointer;color:#00bf8a;margin-left:5px"
                        @click="chooseProduct"
                    >更改</span>
                </span>
                <el-button v-else type="success" class="button-small" @click="chooseProduct">选择商品</el-button>
            </div>
            <div class="part">
                <span>投放时间</span>
                <div>
                    <el-radio-group v-model="copyData.putDate">
                        <el-radio label="选择开始和结束日期"></el-radio>
                        <el-radio label="长期投放"></el-radio>
                    </el-radio-group>
                    <el-date-picker
                        v-model="copyData.beginDate"
                        type="date"
                        placeholder="开始日期"
                        value-format="yyyy-MM-dd"
                        style="margin-left:20px;width:150px;"
                    ></el-date-picker>
                    <el-date-picker
                        v-if="copyData.putDate !== '长期投放'"
                        v-model="copyData.endDate"
                        type="date"
                        placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        style="width:150px;margin-left:10px"
                    ></el-date-picker>
                </div>
            </div>
            <div class="part">
                <span>投放时段</span>
                <div>
                    <el-radio-group v-model="copyData.putTimeSeries">
                        <el-radio label="全天投放"></el-radio>
                        <el-radio label="每日相同时段"></el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="part" v-if="copyData.putTimeSeries === '每日相同时段'">
                <span>每日投放时段</span>
                <div>
                    <el-select v-model="copyData.beginDailyPutTime" style="width:100px">
                        <el-option
                            v-for="(time, index) in timeList "
                            :key="index"
                            :label="time"
                            :value="time"
                        ></el-option>
                    </el-select>
                    <el-select
                        v-model="copyData.endDailyPutTime"
                        style="width:100px;margin-left:10px"
                    >
                        <el-option
                            v-for="(time, index) in timeList"
                            :key="index"
                            :label="time"
                            :value="time"
                        ></el-option>
                    </el-select>
                </div>
            </div>
            <div class="part">
                <span>归因方式</span>
                <span style="color:#00bf8a;font-weight:bold">全网归因</span>
            </div>
            <div class="custom-part" style="padding-top:20px">
                <div class="part-items">
                    <span>投放模式</span>
                    <el-radio-group v-model="copyData.putMode">
                        <el-radio label="均衡投放"></el-radio>
                        <el-radio label="优先跑量"></el-radio>
                        <el-radio label="优先成本"></el-radio>
                    </el-radio-group>
                </div>
                <div class="part-items">
                    <span>优化目标</span>
                    <el-radio-group v-model="copyData.optimizationGoal">
                        <el-radio label="下单"></el-radio>
                        <el-radio label="关注"></el-radio>
                        <el-tooltip content="请自行判断该账号是否有该选项!!!" placement="top">
                            <el-radio label="付费"></el-radio>
                        </el-tooltip>
                    </el-radio-group>
                </div>
                <div class="part-items" v-if="copyData.optimizationGoal === '关注'">
                    <span>深度优化</span>
                    <el-radio-group v-model="copyData.deepConversionOpt">
                        <el-radio label="不启用"></el-radio>
                        <el-radio label="优化转化价值"></el-radio>
                    </el-radio-group>
                </div>
                <div class="part-items" v-if="copyData.deepConversionOpt === '优化转化价值'">
                    <span>价值优化目标</span>
                    <el-radio-group v-model="copyData.deepConversionTarget">
                        <el-radio label="首日付费ROI"></el-radio>
                    </el-radio-group>
                </div>
                <div class="part-items" v-if="copyData.deepConversionTarget === '首日付费ROI'">
                    <span>ROI目标值</span>
                    <c-input
                        v-model="copyData.deepConversionRate"
                        @input="onInput()"
                        :onlyNum="true"
                        style="width:200px"
                    ></c-input>
                </div>
            </div>

            <div class="targeted-packages" v-for="(item, i) in targetPackageList" :key="item.cId">
                <div class="import-packages">
                    <el-button type="warning" @click="handleImportPackAge(i)">导入当前账号定向包</el-button>
                    <br />
                    <el-tooltip content="将当前填入的定向数据保存为一个新的定向包" placement="top">
                        <el-button
                            style="margin-top:20px"
                            type="primary"
                            @click="handleSavePackAge(i)"
                        >保存为定向包</el-button>
                    </el-tooltip>
                </div>
                <i
                    class="el-icon-circle-plus add-icon"
                    @click="addPackage"
                    v-if="i === targetPackageList.length - 1"
                ></i>
                <i class="el-icon-delete-solid delete-icon" v-if="targetPackageList.length > 1" @click="(targetPackageList.splice(i,1),curTargetPackAgeIndex = null)">
                </i>
                <div>
                    <div class="part">
                        <span style="align-self:flex-start;margin-top:10px">地域</span>
                        <div style="display:flex;justify-content:flex-start;align-items:flex-start">
                            <div class="region">
                                <!-- <c-input
                                    :value="getInputValue(item)"
                                    @input="(val) => handleInput(val, i)"
                                    style="width:200px"
                                    placeholder="请输入"
                                ></c-input> -->
                                <div style="height:200px;width:200px;overflow:auto;margin-top:15px">
                                    <div class="choose-all" >
                                        <el-checkbox
                                            label="全选"
                                            v-model="item.isCheckAll"
                                            @change="(value)=>handleCheckAllChange(value,i)"
                                        ></el-checkbox>
                                    </div>
                                    <el-tree
                                        :data="regionList"
                                        :filter-node-method="filterNode"
                                        class="region-tree"
                                        show-checkbox
                                        node-key="id"
                                        :ref="`regionTree`"
                                        @check="(checkedNodes, nodes) => handleNodeChange(checkedNodes, nodes, i)"
                                    ></el-tree>
                                </div>
                            </div>
                            <div class="choose-region" style="margin-left:20px">
                                <p style="margin-top:10px">
                                    已选地域：
                                    <span
                                        v-if="item.chooseRegionList.length > 0"
                                    >({{ item.chooseRegionList.length }})</span>
                                </p>
                                <div>
                                    <el-tag
                                        type
                                        @close="handleCloseTag(region, index,i)"
                                        closable
                                        v-for="(region, index) in item.chooseRegionList"
                                        :key="index"
                                    >{{ region.label }}</el-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="part">
                        <span>年龄</span>
                        <div>
                            <el-select v-model="item.ageMin" style="width:100px">
                                <el-option
                                    v-for="(age, index) in ageList"
                                    :key="index"
                                    :label="age"
                                    :value="age"
                                ></el-option>
                            </el-select>
                            <el-select v-model="item.ageMax" style="width:100px;margin-left:10px">
                                <el-option
                                    v-for="(age, index) in endAgeList"
                                    :key="index"
                                    :label="age"
                                    :value="age"
                                ></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="part">
                        <span>性别</span>
                        <div>
                            <el-radio-group v-model="item.gender">
                                <el-radio label="不限"></el-radio>
                                <el-radio label="男"></el-radio>
                                <el-radio label="女"></el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="part">
                        <span style="align-self:flex-start;margin-top:5px">自定义受众</span>
                        <div>
                            <el-switch v-model="item.customAudience"></el-switch>
                            <div
                                class="audiences-crowd"
                                v-if="item.customAudience"
                                style="margin-top:20px"
                            >
                                <div
                                    style="margin-right:20px;"
                                    :class="{ isNotEmpty: Array.isArray(item.directionalCrowd) && item.directionalCrowd.length > 0 }"
                                >
                                    <el-button
                                        class="button-small"
                                        type="success"
                                        plain
                                        v-if="!Array.isArray(item.directionalCrowd) || item.directionalCrowd.length === 0"
                                        @click="chooseDirectCrowd(i)"
                                    >定向人群</el-button>
                                    <div
                                        v-else-if="Array.isArray(item.directionalCrowd) && item.directionalCrowd.length > 0"
                                    >
                                        <div class="c-title">
                                            <span>定向人群</span>
                                            <span @click="chooseDirectCrowd(i)">选择人群</span>
                                        </div>
                                        <div class="crowd-list">
                                            <div
                                                class="crowd-item"
                                                v-for="(crowd, index) in item.directionalCrowd"
                                                :key="crowd.audience_id"
                                            >
                                                <el-tag
                                                    @close="item.directionalCrowd.splice(index, 1)"
                                                    closable
                                                >{{ crowd.name }}</el-tag>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    :class="{ isNotEmpty: Array.isArray(item.excludedCrowd) && item.excludedCrowd.length > 0 }"
                                >
                                    <el-button
                                        class="button-small"
                                        type="warning"
                                        plain
                                        v-if="!Array.isArray(item.excludedCrowd) || item.excludedCrowd.length === 0"
                                        @click="chooseExcludeCrowd(i)"
                                    >排除人群</el-button>
                                    <div
                                        v-else-if="Array.isArray(item.excludedCrowd) && item.excludedCrowd.length > 0"
                                    >
                                        <div class="c-title">
                                            <span>排除人群</span>
                                            <span @click="chooseExcludeCrowd(i)">排除人群</span>
                                        </div>
                                        <div class="crowd-list">
                                            <div
                                                class="crowd-item"
                                                v-for="(crowd, index) in item.excludedCrowd"
                                                :key="crowd.audience_id"
                                            >
                                                <el-tag
                                                    type="warning"
                                                    @close="item.excludedCrowd.splice(index, 1)"
                                                    closable
                                                >{{ crowd.name }}</el-tag>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="part">
                        <span>自动扩量</span>
                        <el-switch v-model="item.expandEnabled"></el-switch>
                    </div>
                    <div class="part" v-if="item.expandEnabled === true">
                        <span>
                            不可突破定向
                            <el-tooltip
                                content="于不可突破定向
                                系统将自动为你扩展适合您广告的人群，你可以指定不可突破定向来限制扩展范围。
                                例如，选择定向女性人群，并将性别勾选为不可突破定向，系统扩展人群将被限制在女性人群范围内"
                                placement="top"
                            >
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </span>
                        <div>
                            <el-checkbox-group v-model="item.expandTarget">
                                <el-checkbox label="地域"></el-checkbox>
                                <el-checkbox label="年龄"></el-checkbox>
                                <el-checkbox label="性别"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div class="part" v-if="item.expandEnabled === true">
                        <span style="align-self:flex-start;margin-top:5px">
                            扩量种子人群
                            <el-tooltip
                                content="人群包质量会影响广告效果，建议使用您希望获取的目标人群，且人群尽可能精准。如：您希望优化游戏付费行为，则可使用游戏内的高质量付费人群包。人群包大小建议为100-10000。"
                                placement="top"
                            >
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </span>
                        <div
                            :class="{ isNotEmpty: Array.isArray(item.coldStartAudience) && item.coldStartAudience.length > 0 }"
                        >
                            <el-button
                                v-if="!Array.isArray(item.coldStartAudience) || item.coldStartAudience.length === 0"
                                type="success"
                                plain
                                class="button-small"
                                @click="chooseSeedCrowd(i)"
                            >选择种子人群</el-button>
                            <div
                                v-else-if="Array.isArray(item.coldStartAudience) && item.coldStartAudience.length > 0"
                            >
                                <div class="c-title">
                                    <span>种子人群</span>
                                    <span @click="chooseSeedCrowd(i)">种子人群</span>
                                </div>
                                <div class="crowd-list">
                                    <div
                                        class="crowd-item"
                                        style="margin:10px 0;"
                                        v-for="(crowd, index) in item.coldStartAudience"
                                        :key="crowd.audience_id"
                                    >
                                        <el-tag
                                            type="info"
                                            @close="item.coldStartAudience.splice(index, 1)"
                                            closable
                                        >{{ crowd.name }}</el-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="part">
                <span>每日预算</span>
                <c-input
                    v-model="copyData.adgroupDailyBudget"
                    :onlyNum="true"
                    :max="40000000"
                    style="width:300px"
                    placeholder="每日预算有效范围为50-40,000,000元/天"
                />
                <span style="margin-left:5px">元/天</span>
            </div>
            <div class="part">
                <span>出价
                  <el-tooltip content="如果不填入出价，则按照微信自动计算的出价为准！！！" placement="top">
                    <i class="el-icon-question"  >
                  </i>
                  </el-tooltip>

                </span>
                <c-input
                    v-model="copyData.bid"
                    :onlyNum="true"
                    :min="0.1"
                    :max="5000"
                    style="width:300px"
                    placeholder="出价范围0.1~5000元/点击"
                />
                <span style="margin-left:5px">元/千次曝光</span>
            </div>
            <div class="part">
                <span>一键起量</span>
                <el-switch v-model="copyData.keyVolume"></el-switch>
            </div>
            <div class="part" v-if="copyData.keyVolume === true">
                <span>探索预算</span>
                <c-input v-model="copyData.exploreBudget" :onlyNum="true" placeholder="请输入" style="width:300px"></c-input>
                <span style="margin-left:5px">元</span>
            </div>
        </div>
        <choose-crowd
            :show="showCrowdDialog"
            :isChooseDirectCrowd="isChooseDirectCrowd"
            :isChooseSeedCrowd="isChooseSeedCrowd"
            :crowdList="crowdList"
            :limitCrowdNumber="limitCrowdNumber"
            :excludeList="getExcludeList()"
            :echoList="getEchoCrowdList()"
            @chooseCrowd="handleChooseCrowd"
            @close="showCrowdDialog = false"
        ></choose-crowd>
        <el-dialog
            append-to-body
            title="选择定向包"
            width="600px"
            :visible.sync="showPackAgeDialog"
            :close-on-click-modal="false"
            @close="showPackAgeDialog = false"
        >
            <el-table :data="packageList" height="500px">
                <el-table-column label="名称" prop="targetName"></el-table-column>
                <el-table-column label="创建时间" prop="createdTime">
                    <template slot-scope="scope">
                        <span>{{ formatTime(scope.row.createdTime, 'yyyy-MM-dd hh:mm:ss') }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="success"
                            class="button-small"
                            @click="handleLinkPackage(scope)"
                        >选择</el-button>
                        <c-button type="danger" class="button-small" :autoConfirm="true" @confirm="(cb)=>handleDeletePackage(scope.row,cb)">删除</c-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog
            title="选择商品库"
            append-to-body
            width="500px"
            center
            :visible.sync="showDialog"
            :close-on-click-modal="false"
            @close="showDialog = false"
        >
            <div class="library-container" v-loading="libraryLoading">
                <el-radio-group v-model="targetProductCatalogId">
                    <el-radio
                        v-for="item in libraryList"
                        :key="item.product_catalog_id"
                        :label="item.product_catalog_id"
                    >{{ item.product_catalog_name }}</el-radio>
                </el-radio-group>
            </div>
            <div slot="footer" style="text-align:right">
                <el-button @click="(showDialog = false, targetProductCatalogId = '')">取消</el-button>
                <el-button type="primary" @click="handleSuccess('library')">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="选择商品"
            append-to-body
            width="800px"
            center
            top="20px"
            :visible.sync="showProductDialog"
            :close-on-click-modal="false"
            @close="showProductDialog = false"
        >
            <div class="product-container" v-loading="productLoading">
                <div
                    class="product-item"
                    v-for="(item, index) in productList"
                    :class="{ isActive: index === isActiveIndex }"
                    :key="index"
                    @click="clickProduct(index)"
                >
                    <div class="image-info">
                        <img :src="item.product_image_url" alt />
                    </div>
                    <div class="title-info">
                        <p class="line-overflow">{{ item.product_name }}</p>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="showProductDialog = false">取消</el-button>
                <el-button type="primary" @click="handleSuccess('product')">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getWxProjectCrowdList, getPackAgesList, deletePackAge, savePackAge, getWxProjectProducts, getWxProjectProductItem } from '@/api/tencent.js'
import { areaList } from '@/mockData/region'
import chooseCrowd from './choose-crowd.vue'
import { isValidArray, formatTime, isFalsy } from '@/assets/js/utils'
import _ from 'lodash'
import { nanoid } from 'nanoid'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    curInfo: {
      type: Object,
      default: () => ({})
    },
    isEditTemplate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      initializeData: {
        title: `广告${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
        productLibraryName: '',
        expandTarget: [],
        productName: '',
        putDate: '长期投放',
        putMode: '优先跑量',
        putTimeSeries: '全天投放',
        optimizationGoal: '下单',
        gender: '男',
        ageMin: '14岁',
        ageMax: '65岁以上',
        expandEnabled: false,
        keyVolume: false,
        customAudience: false,
        directionalCrowd: [],
        excludedCrowd: [],
        coldStartAudience: [], // 扩量种子人群
        deepConversionOpt: '不启用',
        deepConversionTarget: '',
        deepConversionRate: '',
        beginDailyPutTime: '00:00',
        endDailyPutTime: '24:00'

      },
      targetPackageList: [{
        filterRegion: '', // 每个定向包的地域过滤项
        showCheckAll: false, // 是否全选
        chooseRegionList: [], // 已选地域
        ageMin: '14岁',
        ageMax: '65岁以上',
        gender: '男',
        regionList: _.cloneDeep(areaList),
        customAudience: false, // 自定义受众
        directionalCrowd: [], // 定向人群
        excludedCrowd: [], // 排除人群
        expandTarget: [], // 不可突破定向
        coldStartAudience: [], // 排除种子人群
        cId: nanoid()

      }], // 定向包列表
      isValidArray,
      formatTime,
      isActiveIndex: '',
      copyData: {},
      chooseRegionList: [],
      showCrowdDialog: false,
      isChooseDirectCrowd: false, // 是否正在选择定向人群
      showCheckAll: true,
      //   filterRegion: '',
      isCheckAll: false, // 地域是否全选
      showDialog: false,
      showPackAgeDialog: false,
      showProductDialog: false,
      libraryLoading: false,
      productLoading: false,
      timeList: [
        '00:00', '01:00', '02:00',
        '03:00', '04:00', '05:00',
        '06:00', '07:00', '08:00',
        '09:00', '10:00', '11:00',
        '12:00', '13:00', '14:00',
        '15:00', '16:00', '17:00',
        '18:00', '19:00', '20:00',
        '21:00', '22:00', '23:00',
        '24:00'
      ],
      ageList: [
        '14岁', '18岁',
        '19岁', '20岁', '21岁', '22岁', '23岁',
        '24岁', '25岁', '26岁', '27岁', '28岁',
        '29岁', '30岁', '31岁', '32岁', '33岁',
        '34岁', '35岁', '36岁', '37岁', '38岁',
        '39岁', '40岁', '41岁', '42岁', '43岁',
        '44岁', '45岁', '46岁', '47岁', '48岁',
        '49岁', '50岁', '51岁', '52岁', '53岁',
        '54岁', '55岁', '56岁', '57岁', '58岁',
        '59岁', '60岁', '61岁', '62岁', '63岁',
        '64岁', '65岁'
      ],
      regionList: areaList,
      crowdList: [],
      packageList: [],
      libraryList: [],
      productList: [],
      targetProductCatalogId: '',
      isChooseSeedCrowd: false, // 是否选择种子人群
      packageTemplateName: '', // 定向包模板名称
      limitCrowdNumber: 50,
      curTargetPackAgeIndex: 0 // 当前定向包的下标
    }
  },
  computed: {
    endAgeList () {
      const ageList = this.ageList.slice(4)
      ageList.push('65岁以上')
      return ageList
    }
    // filterRegionList () {
    //   return this.targetPackageList.map(item => item.filterRegion)
    // }
  },

  watch: {
    // filterRegionList: {
    //   handler (newV,oldV) {
    //     this.targetPackageList[this.curTargetPackAgeIndex].showCheckAll = !newV
    //     this.$refs.regionTree[this.curTargetPackAgeIndex].filter(newV)
    //   }
    // },
    show: {
      handler (newV) {
        if (newV === true) {
          this.copyData = Object.assign({}, this.initializeData, _.cloneDeep(this.curInfo))
          if (isValidArray(this.copyData.targetPackageList)) {
            this.targetPackageList = this.copyData.targetPackageList
          }
          if (this.isEditTemplate) {
            this.echoRegionTreeData()
          }
          this.renderData()
        }
      },
      immediate: true
    },
    'copyData.expandEnabled': {
      handler (newV) {
        if (!newV) {
          this.copyData.expandTarget = []
          this.copyData.coldStartAudience = []
        }
      }
    },
    'copyData.customAudience': {
      handler (newV) {
        if (!newV) {
          this.copyData.directionalCrowd = []
          this.copyData.excludedCrowd = []
        }
      }
    },
    'copyData.optimizationGoal': {
      handler (newV) {
        if (newV !== '关注') {
          this.copyData.deepConversionOpt = '不启用'
          this.$set(this.copyData, 'deepConversionTarget', '')
          this.copyData.deepConversionRate = ''
        }
      }
    }
  },
  methods: {
    renderData () {
      getWxProjectCrowdList({
        page: 1,
        pageSize: 15,
        subjectType: this.curInfo.principalPart.type,
        accountId: this.curInfo.marketing.accountId
      }).then(res => {
        this.crowdList = res
      })
    },
    echoRegionTreeData () {
      this.$nextTick(() => {
        this.targetPackageList.forEach((item, index) => {
          const ids = item.chooseRegionList.map(item => item.id)
          this.$refs.regionTree[index].setCheckedKeys(ids)
        })
      })
    },
    chooseProductLibrary () {
      this.showDialog = true
      this.libraryLoading = true
      getWxProjectProducts({
        page: 1,
        pageSize: 999,
        subjectType: this.copyData.principalPart.type,
        accountId: this.copyData.marketing.accountId
      }).then(res => {
        this.libraryList = res
      }).finally(() => {
        this.libraryLoading = false
      })
    },
    chooseProduct () {
      if (!this.copyData.productLibraryName) {
        return this.$message.warning('请选择商品库!')
      }
      this.showProductDialog = true
      this.productLoading = true
      getWxProjectProductItem({
        productCatalogId: this.targetProductCatalogId,
        subjectType: this.copyData.principalPart.type,
        accountId: this.copyData.marketing.accountId
      }).then(res => {
        this.productList = res
      }).finally(() => {
        this.productLoading = false
      })
    },
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleCheckAllChange (value, i) {
      const regionIds = this.regionList.map(item => item.id)
      this.$refs.regionTree[i].setCheckedKeys(value === true ? regionIds : [])
      this.targetPackageList[i].chooseRegionList = value ? _.cloneDeep(this.regionList) : []
    },
    chooseDirectCrowd (i) {
      this.curTargetPackAgeIndex = i
      this.limitCrowdNumber = 50
      this.isChooseSeedCrowd = false
      this.isChooseDirectCrowd = true
      // this.getExcludeList()
      this.showCrowdDialog = true
    },
    chooseExcludeCrowd (i) {
      this.curTargetPackAgeIndex = i
      this.limitCrowdNumber = 50
      this.isChooseDirectCrowd = false
      this.isChooseSeedCrowd = false
      // this.getExcludeList()
      this.showCrowdDialog = true
    },
    chooseSeedCrowd (i) {
      this.curTargetPackAgeIndex = i
      this.limitCrowdNumber = 10
      this.showCrowdDialog = true
      this.isChooseDirectCrowd = false
      this.isChooseSeedCrowd = true
    },
    handleCancel () {
      this.showCrowdDialog = false
    },
    handleNodeChange (checkedNodes, nodes, i) {
      //   this.chooseRegionList = nodes.checkedNodes
      this.targetPackageList[i].chooseRegionList = nodes.checkedNodes
    },
    handleCloseTag (region, index, i) {
      const id = region.id
      this.targetPackageList[i].chooseRegionList.splice(index, 1)
      this.$refs.regionTree[i].setChecked(id, false)
    //   this.chooseRegionList.splice(index, 1)
    //   this.$refs.regionTree.setChecked(id, false)
    },
    handleImportPackAge (i = null) {
      if (i !== null) {
        this.curTargetPackAgeIndex = i
      }
      this.showPackAgeDialog = true
      getPackAgesList({
        page: 1,
        pageSize: 999,
        channelMarketingId: this.copyData.marketing.id,
        targetName: this.packageTemplateName
      }).then(res => {
        this.packageList = res.list
      })
    },
    handleSavePackAge (i) {
      const target = this.targetPackageList[i]
      this.$prompt('请输入定向包名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        savePackAge({
          check_all: target.showCheckAll,
          channel_marketing_id: this.copyData.marketing.id,
          target_name: value,
          region_type: '常驻地',
          region: target.chooseRegionList,
          age_min: target.ageMin,
          age_max: target.ageMax,
          gender: target.gender,
          custom_audience: target.customAudience,
          directional_crowd: target.directionalCrowd,
          excluded_crowd: target.excludedCrowd,
          expand_enabled: target.expandEnabled,
          expand_target: target.expandTarget,
          cold_start_audience: target.coldStartAudience
        }).then(() => {
          this.$message.success('保存成功!')
        })
      })
    },
    handleLinkPackage ({ row }) {
      this.showPackAgeDialog = false
      this.$set(this.targetPackageList, this.curTargetPackAgeIndex, {
        filterRegion: '',
        showCheckAll: !!row.showCheckAll,
        chooseRegionList: JSON.parse(row.region),
        ageMin: row.ageMin,
        ageMax: row.ageMax,
        gender: row.gender,
        customAudience: !!row.customAudience,
        directionalCrowd: JSON.parse(row.directionalCrowd),
        excludedCrowd: JSON.parse(row.excludedCrowd),
        expandEnabled: !!row.expandEnabled,
        expandTarget: JSON.parse(row.expandTarget),
        coldStartAudience: JSON.parse(row.coldStartAudience)
      })
      const regionList = JSON.parse(row.region)
      // ! 回显地域勾线状态
      this.$nextTick(() => {
        this.$refs.regionTree[this.curTargetPackAgeIndex].setCheckedKeys(regionList.map(item => item.id), true)
      })

    },
    getExcludeList () {
      if (isFalsy(this.curTargetPackAgeIndex)) return []
      if (this.isChooseDirectCrowd) {
        return typeof this.targetPackageList[this.curTargetPackAgeIndex].excludedCrowd === 'string' ? JSON.parse(this.targetPackageList[this.curTargetPackAgeIndex].excludedCrowd) : this.targetPackageList[this.curTargetPackAgeIndex].excludedCrowd
      }
      if (!this.isChooseDirectCrowd && !this.isChooseSeedCrowd) {
        return typeof this.targetPackageList[this.curTargetPackAgeIndex].directionalCrowd === 'string' ? JSON.parse(this.targetPackageList[this.curTargetPackAgeIndex].directionalCrowd) : this.targetPackageList[this.curTargetPackAgeIndex].directionalCrowd
      }
      return []
    },
    getEchoCrowdList () {
      if (isFalsy(this.curTargetPackAgeIndex)) return []
      if (this.isChooseDirectCrowd) {
        return typeof this.targetPackageList[this.curTargetPackAgeIndex].directionalCrowd === 'string' ? JSON.parse(this.targetPackageList[this.curTargetPackAgeIndex].directionalCrowd) : this.targetPackageList[this.curTargetPackAgeIndex].directionalCrowd
      }
      if (this.isChooseSeedCrowd) {
        return typeof this.targetPackageList[this.curTargetPackAgeIndex].coldStartAudience === 'string' ? JSON.parse(this.targetPackageList[this.curTargetPackAgeIndex].coldStartAudience) : this.targetPackageList[this.curTargetPackAgeIndex].coldStartAudience
      }
      if (!this.isChooseDirectCrowd) {
        return typeof this.targetPackageList[this.curTargetPackAgeIndex].excludedCrowd === 'string' ? JSON.parse(this.targetPackageList[this.curTargetPackAgeIndex].excludedCrowd) : this.targetPackageList[this.curTargetPackAgeIndex].excludedCrowd
      }

      return []
    },
    getCurrentData () {
      return {
        // regionType: '常驻地',
        // region: this.chooseRegionList,
        // ageMin: this.copyData.ageMin,
        // ageMax: this.copyData.ageMax,
        // gender: this.copyData.gender,
        // customAudience: this.copyData.customAudience,
        // directionalCrowd: this.copyData.directionalCrowd,
        // excludedCrowd: this.copyData.excludedCrowd,
        // expandEnabled: this.copyData.expandEnabled,
        // expandTarget: this.copyData.expandTarget,
        // coldStartAudience: this.copyData.coldStartAudience,
        targetPackageList: this.targetPackageList,
        // todo=>
        productLibraryName: this.copyData.productLibraryName,
        productName: this.copyData.productName,
        putDate: this.copyData.putDate,
        beginDate: this.copyData.beginDate,
        endDate: this.copyData.putDate !== '长期投放' ? this.copyData.endDate : '',
        putTimeSeries: this.copyData.putTimeSeries,
        beginDailyPutTime: this.copyData.beginDailyPutTime,
        endDailyPutTime: this.copyData.endDailyPutTime,
        bidMode: '智能优化',
        putMode: this.copyData.putMode,
        optimizationGoal: this.copyData.optimizationGoal,
        deepConversionOpt: this.copyData.deepConversionOpt,
        deepConversionTarget: this.copyData.deepConversionTarget,
        deepConversionRate: this.copyData.deepConversionRate,
        bid: this.copyData.bid,
        adgroupDailyBudget: this.copyData.adgroupDailyBudget,
        keyVolume: this.copyData.keyVolume,
        exploreBudget: this.copyData.exploreBudget
      }
    },
    handleSuccess (type) {
      if (type === 'library') {
        this.showDialog = false
        this.copyData.productLibraryName = this.libraryList.find(item => item.product_catalog_id === this.targetProductCatalogId).product_catalog_name
      }
      if (type === 'product') {
        this.showProductDialog = false
        this.copyData.productName = this.productList[this.isActiveIndex].product_name
      }
    },
    clickProduct (index) {
      this.isActiveIndex = index
    },
    handleChooseCrowd (crowdList) {
      if (this.isChooseDirectCrowd) {
        this.targetPackageList[this.curTargetPackAgeIndex].directionalCrowd = crowdList
      }
      if (this.isChooseSeedCrowd) {
        this.targetPackageList[this.curTargetPackAgeIndex].coldStartAudience = crowdList
      }
      if (!this.isChooseDirectCrowd) {
        this.targetPackageList[this.curTargetPackAgeIndex].excludedCrowd = crowdList
      }
    },
    onInput () {
      // ! 解决el-input框输入没反应的问题
      this.$forceUpdate()
    },
    handleInput (val, i) {
      this.curTargetPackAgeIndex = i
      this.targetPackageList[i].filterRegion = val
    },
    getInputValue (item) {
      return item.filterRegion
    },
    handleDeletePackage (row, cb) {
      deletePackAge(row.id).then(() => {
        this.$message.success('删除成功!')
        this.handleImportPackAge()
      }).finally(() => {
        cb()
      })
    },
    addPackage () {
      this.targetPackageList.push({
        filterRegion: '', // 每个定向包的地域过滤项
        showCheckAll: false, // 是否全选
        chooseRegionList: [], // 已选地域
        ageMin: '14岁',
        ageMax: '65岁以上',
        gender: '男',
        customAudience: false, // 自定义受众
        directionalCrowd: [], // 定向人群
        excludedCrowd: [], // 排除人群
        expandTarget: [], // 不可突破定向
        coldStartAudience: [], // 排除种子人群
        id: nanoid(),
        regionList: _.cloneDeep(areaList)
      })
    },
    resetData () {
      this.initializeData = {
        title: `${new Date().getFullYear()}-${new Date().getMonth()}${new Date().getDay()}`,
        productLibraryName: '',
        expandTarget: [],
        productName: '',
        putDate: '长期投放',
        putMode: '优先跑量',
        putTimeSeries: '全天投放',
        optimizationGoal: '下单',
        gender: '男',
        ageMin: '14岁',
        ageMax: '65岁以上',
        expandEnabled: false,
        keyVolume: false,
        customAudience: false,
        directionalCrowd: [],
        excludedCrowd: [],
        coldStartAudience: [], // 扩量种子人群
        deepConversionOpt: '不启用'
      }
      this.targetPackageList = [{
        filterRegion: '', // 每个定向包的地域过滤项
        showCheckAll: false, // 是否全选
        chooseRegionList: [], // 已选地域
        regionList: _.cloneDeep(areaList),
        ageMin: '14岁',
        ageMax: '65岁以上',
        gender: '男',
        customAudience: false, // 自定义受众
        directionalCrowd: [], // 定向人群
        excludedCrowd: [], // 排除人群
        expandTarget: [], // 不可突破定向
        coldStartAudience: [], // 排除种子人群
        cId: nanoid()
      }] // 定向包列表
      //   this.$refs.regionTree.setCheckedKeys([])
      this.copyData = {}
      this.isCheckAll = false
      this.chooseRegionList = []
      this.isActiveIndex = ''
      this.isChooseDirectCrowd = false
      this.isChooseSeedCrowd = false
      this.beginDailyPutTime = '00:00'
      this.endDailyPutTime = '24:00'
      this.packageTemplateName = ''
      this.targetProductCatalogId = ''
      this.curTargetPackAgeIndex = null
    }
  },
  components: { chooseCrowd }
}
</script>
<style lang='scss' scoped>
#step-two {
    margin-top: 50px;
}

.part {
    @include flex(flex-start, center);
    margin: 30px 0;
    > span:nth-of-type(1) {
        width: 200px;
        flex-shrink: 0;
        text-align: right;
        margin-right: 20px;
    }
}
.custom-part {
    .part-items {
        @include flex(flex-start, center);
        margin-bottom: 20px;
        > span {
            width: 200px;
            flex-shrink: 0;
            text-align: right;
            margin-right: 20px;
        }
    }
}
.choose-all {
    @include flex(flex-end, center) padding: 0 10px;
}
.choose-region {
    > div {
        @include flex(flex-start, center, row, wrap);
        margin-top: 24px;
        > span {
            margin: 0 10px 10px 0;
        }
    }
}
.targeted-packages {
    margin-bottom:20px;
    border: 1px solid #ececec;
    border-radius: 8px;
    position: relative;
    @include flex(flex-start, flex-start);
    .import-packages {
        padding: 30px;
    }
    .add-icon {
        position: absolute;
        top: 2%;
        left: 95%;
        font-size: 36px;
        color: $main;
        cursor: pointer;
        display:none;
    }
    .delete-icon{
        position: absolute;
        left:0;
        top:-2%;
        font-size:36px;
        color:$colorRed;
        cursor: pointer;
        display:none;
    }
    &:hover{
        .delete-icon,.add-icon{
            display:block
        }
    }
    .part {
        > span:nth-of-type(1) {
            width: 120px;
        }
    }
}
.audiences-crowd {
    @include flex(flex-start, flex-start);
    .crowd-list {
        .crowd-item {
            margin: 10px 0;
        }
    }
}
.c-title {
    @include flex(space-between, center);
    > span:nth-of-type(2) {
        cursor: pointer;
        font-weight: bold;
    }
}
.product-container {
    @include flex(flex-start, flex-start, row, wrap);
    .product-item {
        margin: 0 20px 20px 0;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 8px;
        cursor: pointer;
        .image-info {
            background: #ececec;
            border-radius: inherit;
            width: 120px;
            height: 62px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .title-info {
            > p {
                padding-top: 4px;
            }
        }
        &:hover {
            border-color: $main;
            color: $main;
        }
    }
    .isActive {
        box-shadow: 0px 0px 1px 2px $main;
        border-color: $main;
        color: $main;
    }
}
.library-container {
    max-height: 700px;
    overflow: auto;
    /deep/ .el-radio-group {
        @include flex(flex-start, flex-start, row, wrap);
        .el-radio {
            width: 30%;
            margin: 0 0 20px 0;
        }
    }
}
.isNotEmpty {
    border: 1px solid #ececec;
    border-radius: 6px;
    padding: 10px;
}
</style>

<template>
  <el-dialog
    title="选择种子人群"
    width="1200px"
    :visible.sync="show"
    append-to-body
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <div class="main-container">
      <div class="table-container">
        <div class="filter-operation">
          <c-input v-model="search" style="width:300px" placeholder="请输入名称搜索人群包"></c-input>
        </div>
        <div>
          <el-table
            :data="filterCrowdList"
            height="500px"
            row-key="audience_id"
            ref="tableRef"
            @selection-change="handleSelectionChange"
            @row-click="handleRowClick"
          >
            <el-table-column
              type="selection"
              width="55"
              reserve-selection
              :selectable="checkSelectable"
            ></el-table-column>
            <el-table-column label="人群名称" prop="name"></el-table-column>
            <el-table-column label="覆盖人群" prop="user_count"></el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">{{ `${scope.row.status === 'SUCCESS' ? '已生效' : '已失效'}` }}</template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="choose-container">
        <div style="display:inline-block;">
          已选择
          <span v-if="isValidArray(chooseCrowdList)">{{ chooseCrowdList.length }} / {{limitCrowdNumber}} 个人群包</span>
          <span v-else>0个人群包</span>
          <span style="margin-left:20px" v-if="isValidArray(chooseCrowdList)">
            预计覆盖人数:
            <span style="color:#00bf8a;font-weight:bold">{{ getTotalNumber() }}</span>
          </span>
        </div>
        <div class="c-container">
          <el-tag v-for="item in chooseCrowdList" :key="item.audience_id">{{ item.name }}</el-tag>
        </div>
      </div>
    </div>
    <div slot="footer" style="text-align:right">
      <el-button @click="handleClose">取消</el-button>
      <el-button @click="handleConfirm" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { isValidArray } from '@/assets/js/utils'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    crowdList: {
      type: Array,
      default: () => ([])
    },
    isChooseDirectCrowd: {
      type: Boolean,
      default: false
    },
    isChooseSeedCrowd: {
      type: Boolean,
      default: false
    },
    excludeList: {
      type: Array,
      default: () => ([])
    },
    echoList: {
      type: Array,
      default: () => ([])
    },
    limitCrowdNumber:{
      type:Number,
      default:50,
    }

  },
  data () {
    return {
      search: '',
      chooseCrowdList: [],
      isValidArray,
      copyCrowdList: []
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.copyCrowdList = _.cloneDeep(this.crowdList)
          this.$nextTick(() => {
            // 回显勾选状态
            this.echoData()
          })
        }
      }
    }
  },
  computed: {
    filterCrowdList () {
      const dataList = this.isChooseSeedCrowd ? this.copyCrowdList.filter(item => item.type === 'CUSTOMER_FILE') : this.copyCrowdList
      if (!this.search) return dataList
      return JSON.parse(JSON.stringify(dataList.filter(item => item.name.includes(this.search))))
    }
  },
  methods: {
    echoData () {
      if (this.echoList.length > 0) {
        for (const crowd of this.filterCrowdList) {
          if (this.echoList.find(item => item.audience_id === crowd.audience_id)) {
            this.$refs.tableRef.toggleRowSelection(crowd, true)
          }
        }
      }
    },
    handleClose () {
      this.chooseCrowdList = []
      this.copyCrowdList = JSON.parse(JSON.stringify(this.crowdList))
      this.search = ''
      this.$refs.tableRef.clearSelection()
      this.$emit('close')
    },
    handleRowClick (row) {
      if(this.excludeList.find(item => item.audience_id === row.audience_id)) {
         return 
      }
      this.$refs.tableRef.toggleRowSelection(row)
    },
    handleSelectionChange (select) {
      this.chooseCrowdList = JSON.parse(JSON.stringify(select))
    },
    handleCloseCrowd (val, index) {
      this.chooseCrowdList.splice(index, 1)
    },
    getTotalNumber () {
      return this.chooseCrowdList.reduce((acc, b) => {
        return acc + b.user_count
      }, 0)
    },
    handleConfirm () {
      if (!isValidArray(this.chooseCrowdList)) return this.$message.warning('请勾选人群!')
      if (this.chooseCrowdList.length > this.limitCrowdNumber) return this.$message.error(`至多勾选${this.limitCrowdNumber}个人群包`)
      this.$emit('chooseCrowd', this.chooseCrowdList)
      this.$emit('close')
    },
    checkSelectable (row) {
      if (this.excludeList.find(item => item.audience_id === row.audience_id)) {
        return false
      }
      return true
    }

  },
  components: {}
}
</script>
<style lang='scss' scoped>
.main-container {
  @include flex(flex-start, flex-start) .table-container {
    width: 65%;
  }
  .choose-container {
    margin-left: 20px;
    margin-top: 10px;
    .c-container {
      margin-top: 30px;
      max-height: 480px;
      overflow: auto;
      @include flex(flex-start, flex-start, column);
      > span {
        margin-bottom: 25px;
      }
    }
  }
}
</style>

<template>
  <div id="step-three">
    <h2 style="font-size:18px;display:inline-block">创意内容</h2>
     <el-tooltip content="不同模式下，是否需要填入文案和文案的种类可能不尽相同！！！" placement="top">
       <span style="color:#ccc;font-size:13px;margin-left:15px">(创建计划的个数= 定向包个数 * 推广页数量 * （文案数量） )</span>
     </el-tooltip>
    <div class="container">
      <template v-if="copyData.putPosition === '朋友圈信息流'">
        <div class="part">
          <span>广告样式</span>
          <div>
            <el-radio-group v-model="copyData.adStyle">
              <el-radio label="常规广告"></el-radio>
              <el-radio label="卡片式广告"></el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="part">
          <span>样式广告</span>
          <span v-if="copyData.adStyle === '常规广告'" class="fixed-text">常规广告</span>
          <span v-else class="fixed-text">卡片式广告</span>
        </div>
        <div class="part" v-if="copyData.adStyle === '卡片式广告'">
          <span style="align-self:flex-start;margin-top:5px">点击跳转</span>
          <div>
            <el-button
              v-if="copyData.pageNameList.length === 0"
              type="primary"
              class="button-small"
              @click="choosePromotionPage"
            >选择原生推广页</el-button>
            <div class="c-info" v-else>
              <div class="c-info-title">
                <span>
                  推广页
                  <span
                    v-if="copyData.pageNameList.length > 0"
                  >({{ copyData.pageNameList.length }})</span>
                </span>
                <span style="font-weight: bold; cursor: pointer;" @click="choosePromotionPage">选择</span>
              </div>
              <div class="c-list">
                <div
                  style="display:flex;justify-content:flex-start;align-items:center"
                  v-for="(item, index) in copyData.pageNameList"
                  :key="item.pageId"
                >
                  <el-tag
                    type="success"
                    closable
                    style="width:200px"
                    @close="handleRemovePageItem(index)"
                  >
                    <div
                      class="line-overflow"
                      style="width:150px"
                      :title="item.pageName"
                    >{{ item.pageName }}</div>
                  </el-tag>
                  <div style="margin-left:10px">
                    <el-button
                      v-if="!isValidObject(copyData.materialList[index])"
                      class="button-small"
                      @click="chooseOuterPic(item, index)"
                    >选择外层素材</el-button>
                    <el-popover v-else placement="top-start" trigger="hover" title="原素材">
                      <div class="original-image">
                        <img
                          v-if="copyData.materialList[index].type === 1"
                          :src="copyData.materialList[index].url"
                          alt
                        />
                        <video muted controls v-else :src="copyData.materialList[index].url"></video>
                      </div>
                      <div slot="reference">
                        <div
                          style="width:100px;display:inline-block"
                          class="line-overflow"
                          :title="copyData.materialList[index].title"
                        >{{ copyData.materialList[index].title }}</div>
                        <span
                          style="margin-left:10px;cursor: pointer; vertical-align: top;"
                          @click="chooseOuterPic(item, index)"
                        >更改</span>
                      </div>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="part" v-if="copyData.adStyle === '卡片式广告'">
          <span>卡片组件</span>
          <div>
            <el-checkbox-group v-model="copyData.cardComponent">
              <el-checkbox label="行动按钮"></el-checkbox>
              <el-checkbox label="标签"></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div
          class="part"
          v-if="copyData.adStyle === '卡片式广告' && copyData.cardComponent.includes('标签')"
        >
          <span>标签展示</span>
          <div>
            <el-button
              v-if="!isValidArray(copyData.chooseTag)"
              class="button-small"
              @click="chooseTags(16,3)"
            >选择标签</el-button>
            <div v-else class="tag-list">
              <el-tag
                v-for="(tag, index) in copyData.chooseTag"
                type="info"
                @close="copyData.chooseTag.splice(index, 1)"
                closable
                style="margin-right:10px"
                :key="index"
              >{{ tag }}</el-tag>
              <span style="color:#00bf8a;margin-left:5px;cursor: pointer;" @click="()=>chooseTags(16,3)">更改</span>
            </div>
          </div>
        </div>
        <div
          class="part"
          v-if="copyData.adStyle === '卡片式广告' && copyData.cardComponent.includes('行动按钮')"
        >
          <span>按钮文案</span>
          <div>
            <el-radio-group v-model="copyData.buttonCopy">
              <el-radio label="阅读小说"></el-radio>
              <el-radio label="关注公众号"></el-radio>
              <el-radio label="了解更多"></el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="part" v-if="copyData.adStyle === '卡片式广告'">
          <span style="align-self:flex-start;">
            外层广告标题
            <i style="cursor:help" class="el-icon-question" title='"标题"数量需要与"外层广告文案"数量相等"' />
          </span>
          <div class="outer-text-editor-wrapper">
            <div class="editor-container" v-for="(item, index) in editorTitleEditor" :key="item.id">
              <div>
                <div :id="`${item.id}`" class="editor" :class="{ showWarning: item.textLen > 10 }"></div>
                <div class="limit-statistics" style="margin:8px 0">
                  <span style="color:#00bf8a">{{ item.emojiLen }}</span>/1 |
                  <span style="color:#00bf8a">{{ item.textLen }}</span>/10
                </div>
                <p
                  v-show="item.textLen > 40"
                  style="color:rgba(242,77,77);font-size:13px"
                >广告文案标题不得超过0个字符，请缩短输入内容</p>
              </div>
              <i
                class="el-icon-circle-plus"
                v-if="isValidArray(editorTitleEditor) && index === editorTitleEditor.length - 1"
                @click="addEditor('title')"
              ></i>
              <i
                class="el-icon-delete-solid"
                v-if="isValidArray(editorTitleEditor) && editorTitleEditor.length > 1"
                @click="removeEditor('title', index, item)"
              ></i>
            </div>
          </div>
        </div>
        <div class="part" v-if="copyData.adStyle === '卡片式广告'">
          <span>按钮跳转</span>
          <span class="fixed-text">原生推广页</span>
        </div>
        <div class="part">
          <span style="align-self:flex-start;">
            外层文案
            <i
              style="cursor:help"
              class="el-icon-question"
              title="单个文案不能超过40个字符。
                      --请勿输入 \ < > [ ] # ^ & * 等上述特殊字符
                      --请勿在文案中间输入空格！"
            ></i>
          </span>
          <div class="outer-text-editor-wrapper">
            <div class="editor-container" v-for="(item, index) in editorList" :key="item.id">
              <div>
                <div
                  :id="`${item.id}`"
                  class="editor"
                  :class="{ showWarning: copyData.adStyle === '卡片式广告' ? item.textLen > 10 : item.textLen > 40 }"
                ></div>
                <div class="limit-statistics" style="margin:8px 0">
                  <span style="color:#00bf8a">{{ item.emojiLen }}</span>
                  / {{ copyData.adStyle === '卡片式广告' ? 1 : 4 }} |
                  <span
                    style="color:#00bf8a"
                  >{{ item.textLen }}</span>
                  / {{ copyData.adStyle === '卡片式广告' ? 10 : 40 }}
                </div>
                <p
                  v-show="item.textLen > 40"
                  style="color:rgba(242,77,77);font-size:13px"
                >广告文案不得超过40字，请缩短输入内容</p>
              </div>
              <i
                class="el-icon-circle-plus"
                v-if="isValidArray(editorList) && index === editorList.length - 1"
                @click="addEditor('text')"
              ></i>
              <i
                class="el-icon-delete-solid"
                v-if="editorList.length > 1"
                @click="removeEditor('text', index, item)"
              ></i>
            </div>
          </div>
        </div>
        <div class="part" v-if="copyData.adStyle === '常规广告'">
          <span style="align-self:flex-start;margin-top:5px">外层素材跳转</span>
          <div>
            <el-button
              v-if="copyData.pageNameList.length === 0"
              type="primary"
              class="button-small"
              @click="choosePromotionPage"
            >选择原生推广页</el-button>
            <div class="c-info" v-else>
              <div class="c-info-title">
                <span>
                  推广页
                  <span
                    v-if="copyData.pageNameList.length > 0"
                  >({{ copyData.pageNameList.length }})</span>
                </span>
                <span style="font-weight: bold;" @click="choosePromotionPage">选择</span>
              </div>
              <div class="c-list">
                <div v-for="(item, index) in copyData.pageNameList" :key="item.pageId">
                  <el-tag
                    type="success"
                    closable
                    @close="copyData.pageNameList.splice(index, 1)"
                  >{{ item.pageName }}</el-tag>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="part" v-if="copyData.adStyle === '常规广告'">
          <span>文字链文案</span>
          <div>
            <el-radio-group v-model="copyData.textLinkCopy">
              <el-radio label="阅读小说"></el-radio>
              <el-radio label="关注公众号"></el-radio>
              <el-radio label="了解更多"></el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="part" v-if="copyData.adStyle === '常规广告'">
          <span>文字链跳转</span>
          <span class="fixed-text">原生推广页</span>
        </div>
        <div class="part">
          <span style="align-self:flex-start;margin-top:4px">数据展示</span>
          <div>
            <el-switch v-model="copyData.dataShow"></el-switch>
            <p style="font-size:12px;margin-top:4px">展示本账户下相同推广目标的广告，近30日内的总转化目标量；数据 ≥50 时展示</p>
          </div>
        </div>
        <div class="part" v-if="copyData.dataShow === true">
          <span>数据类型</span>
          <el-radio-group v-model="copyData.dataType">
            <el-radio label="转化目标量"></el-radio>
          </el-radio-group>
        </div>
      </template>
      <template v-if="copyData.putPosition === '公众平台流量'">
        <template v-if="copyData.putWayValue === '公众号文章底部'">
          <div class="part">
            <span>创意样式</span>
            <div>
              <el-radio-group v-model="copyData.bmCreativeStyle">
                <el-radio label="大图式卡片"></el-radio>
                <el-radio label="图片式卡片"></el-radio>
                <el-radio label="名片式banner"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="part" v-if="copyData.bmCreativeStyle === '名片式banner'">
            <span style="align-self:flex-start;margin-top:15px">
              关注引导文案
              <span v-if="copyData.guidingCopyList.length > 0">({{ copyData.guidingCopyList.length }})</span>
            </span>
            <div>
              <div
                class="card-copy-list"
                v-for="(item, index) in copyData.guidingCopyList"
                :key="index"
              >
                <c-input
                  v-model="copyData.guidingCopyList[index]"
                  style="width:200px;margin:5px 0"
                  placeholder="关注引导文案"
                />
                <i
                  class="el-icon-circle-plus add-input input-icon"
                  @click=" copyData.guidingCopyList.every(item=>!isFalsy(item)) && copyData.guidingCopyList.push('')"
                  v-if="index === copyData.guidingCopyList.length - 1"
                ></i>
                <i
                  class="el-icon-delete-solid delete-input input-icon"
                  @click="copyData.guidingCopyList.splice(index, 1)"
                  v-if="copyData.guidingCopyList.length > 1"
                ></i>
              </div>
            </div>
          </div>
          <div class="part" v-if="copyData.bmCreativeStyle === '名片式banner'">
            <span>外层素材跳转</span>
            <span class="fixed-text">原生推广页</span>
          </div>
          <div class="part" v-if="['大图式卡片', '图片式卡片'].includes(copyData.bmCreativeStyle)">
            <span style="align-self:flex-start;margin-top:5px">广告位图片</span>
            <div>
              <el-button plain @click="uploadFile('image')">上传图片</el-button>
              <div style="color:#ccc;margin-top:5px">
                <span v-if="copyData.bmCreativeStyle === '大图式卡片'">
                  请上传右图所示广告位图片
                  图片大小为960像素x540像素，支持bmp, png, jpeg, jpg格式
                </span>
                <span v-else>
                  请上传右图所示广告位图片
                  图片大小为960*334像素，支持bmp, png, jpeg, jpg格式
                </span>
              </div>
              <div class="ad-brand-image">
                <img v-if="isValidArray(copyData.materialList)" :src="copyData.materialList[0].url" />
              </div>
            </div>
          </div>
          <template v-if="copyData.bmCreativeStyle === '大图式卡片'">
            <div class="part">
              <span>信息栏类型</span>
              <div>
                <el-radio-group v-model="copyData.bmInformationBarType">
                  <el-radio label="头像昵称型"></el-radio>
                  <el-radio label="文案型"></el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="part" v-if="copyData.bmInformationBarType === '文案型'">
              <span style="align-self:flex-start;margin-top:15px">信息栏文案 <span v-if="copyData.msgBoardCopyList.length > 0">({{copyData.msgBoardCopyList.length}})</span></span>
              <div>
                <div
                  class="card-copy-list"
                  v-for="(item, index) in copyData.msgBoardCopyList"
                  :key="index"
                >
                  <c-input
                    v-model="copyData.msgBoardCopyList[index]"
                    style="width:200px;margin:5px 0"
                    placeholder="信息栏文案"
                  />
                  <i
                    class="el-icon-circle-plus add-input input-icon"
                    @click="copyData.msgBoardCopyList.every(item=>!isFalsy(item)) &&  copyData.msgBoardCopyList.push('')"
                    v-if="index === copyData.msgBoardCopyList.length - 1"
                  ></i>
                  <i
                    class="el-icon-delete-solid delete-input input-icon"
                    @click="copyData.msgBoardCopyList.splice(index, 1)"
                    v-if="copyData.msgBoardCopyList.length > 1"
                  ></i>
                </div>
              </div>
            </div>
          </template>
          <template v-if="['大图式卡片', '图片式卡片'].includes(copyData.bmCreativeStyle)">
            <div class="part">
              <span>标签展示</span>
              <div>
                <el-radio-group v-model="copyData.bmiTagShow">
                  <template v-if="copyData.bmCreativeStyle === '大图式卡片'">
                    <el-radio label="不启用"></el-radio>
                    <el-radio label="行业标签"></el-radio>
                  </template>
                  <el-radio label="转化目标量标签"></el-radio>
                  <el-radio label="好友关注量标签"></el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="part" v-if="copyData.bmiTagShow === '行业标签'">
              <span>选择标签</span>
              <el-button v-if="!isValidArray(copyData.bmChooseTag)" @click="chooseTags(12, 3)">选择标签</el-button>
              <div v-else class="tag-list">
                <el-tag
                  v-for="(tag, index) in copyData.bmChooseTag"
                  type="info"
                  @close="copyData.bmChooseTag.splice(index, 1)"
                  closable
                  style="margin-right:10px"
                  :key="index"
                >{{ tag }}</el-tag>
                <span style="color:#00bf8a;margin-left:5px;cursor: pointer;" @click="()=>chooseTags(12,3)">更改</span>
              </div>
            </div>
          </template>
          <div class="part">
            <span>点击跳转</span>
            <span class="fixed-text">原生推广页</span>
          </div>
          <div class="part">
            <span>点击按钮跳转</span>
            <span class="fixed-text">原生推广页</span>
          </div>
          <div class="part">
            <span style="align-self: flex-start;margin-top:5px">原生推广页</span>
            <div>
              <el-button
                v-if="!isValidArray(copyData.pageNameList)"
                plain
                @click="choosePromotionPage"
              >选择原生推广页</el-button>
              <div class="c-info" v-else>
                <div class="c-info-title">
                  <span>
                    推广页
                    <span
                      v-if="copyData.pageNameList.length > 0"
                    >({{ copyData.pageNameList.length }})</span>
                  </span>
                  <span style="font-weight: bold;" @click="choosePromotionPage">选择</span>
                </div>
                <div class="c-list">
                  <div v-for="(item, index) in copyData.pageNameList" :key="item.pageId">
                    <el-tag
                      type="success"
                      closable
                      @close="copyData.pageNameList.splice(index, 1)"
                    >{{ item.pageName }}</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="part">
            <span>按钮文案</span>
            <div>
              <el-radio-group v-model="copyData.bmiButtonCopy">
                <el-radio label="阅读小说"></el-radio>
                <el-radio label="关注公众号"></el-radio>
                <el-radio label="了解更多"></el-radio>
              </el-radio-group>
            </div>
          </div>
        </template>
        <template v-else-if="copyData.putWayValue === '公众号文章中部'">
          <div class="part">
            <span>创意样式</span>
            <span class="fixed-text">图片式卡片</span>
          </div>
          <div class="part">
            <span style="align-self:flex-start;margin-top:10px">外层卡片</span>
            <div>
              <el-button plain @click="uploadFile('image')">上传图片</el-button>
              <div style="color:#ccc;margin-top:5px">
                <span>
                  请上传右图所示广告位图片
                  图片大小为960像素x540像素，支持bmp, png, jpeg, jpg格式
                </span>
              </div>
              <input ref="uploadImage" type="file" style="display:none" />
              <div class="ad-brand-image">
                <img v-if="isValidArray(copyData.materialList)" :src="copyData.materialList[0].url" />
              </div>
            </div>
          </div>
          <div class="part">
            <span>信息栏类型</span>
            <div>
              <el-radio-group v-model="copyData.bmInformationBarType">
                <el-radio label="头像昵称型"></el-radio>
                <el-radio label="文案型"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="part" v-if="copyData.bmInformationBarType === '文案型'">
            <span style="align-self:flex-start;margin-top:15px">信息栏文案 <span v-if='copyData.msgBoardCopyList.length > 0'>({{copyData.msgBoardCopyList.length}})</span></span>
            <div>
              <div
                class="card-copy-list"
                v-for="(item, index) in copyData.msgBoardCopyList"
                :key="index"
              >
                <c-input
                  v-model="copyData.msgBoardCopyList[index]"
                  style="width:200px;margin:5px 0"
                  placeholder="信息栏文案"
                />
                <i
                  class="el-icon-circle-plus add-input input-icon"
                  @click="copyData.msgBoardCopyList.every(item=>!isFalsy(item)) && copyData.msgBoardCopyList.push('')"
                  v-if="index === copyData.msgBoardCopyList.length - 1"
                ></i>
                <i
                  class="el-icon-delete-solid delete-input input-icon"
                  @click="copyData.msgBoardCopyList.splice(index, 1)"
                  v-if="copyData.msgBoardCopyList.length > 1"
                ></i>
              </div>
            </div>
          </div>
          <div class="part">
            <div class="part">
              <span>标签展示</span>
              <div>
                <el-radio-group v-model="copyData.bmiTagShow">
                  <el-radio label="不启用"></el-radio>
                  <el-radio label="行业标签"></el-radio>
                  <el-radio label="转化目标量标签"></el-radio>
                  <el-radio label="好友关注量标签"></el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="part" v-if="copyData.bmiTagShow === '行业标签'">
            <span>选择标签</span>
            <el-button v-if="!isValidArray(copyData.bmChooseTag)" @click="chooseTags(12, 3)">选择标签</el-button>
            <div v-else class="tag-list">
              <el-tag
                v-for="(tag, index) in copyData.bmChooseTag"
                type="info"
                @close="copyData.bmChooseTag.splice(index, 1)"
                closable
                style="margin-right:10px"
                :key="index"
              >{{ tag }}</el-tag>
              <span style="color:#00bf8a;margin-left:5px;cursor: pointer;" @click="()=>chooseTags(12,3)">更改</span>
            </div>
          </div>
          <div class="part">
            <span>外层素材跳转</span>
            <span class="fixed-text">原生推广页</span>
          </div>
          <div class="part">
            <span>点击按钮跳转</span>
            <span class="fixed-text">点击按钮跳转</span>
          </div>
          <div class="part">
            <span style="align-self: flex-start;margin-top:5px">原生推广页</span>
            <div>
              <el-button
                v-if="!isValidArray(copyData.pageNameList)"
                plain
                @click="choosePromotionPage"
              >选择原生推广页</el-button>
              <div class="c-info" v-else>
                <div class="c-info-title">
                  <span>
                    推广页
                    <span
                      v-if="copyData.pageNameList.length > 0"
                    >({{ copyData.pageNameList.length }})</span>
                  </span>
                  <span style="font-weight: bold;" @click="choosePromotionPage">选择</span>
                </div>
                <div class="c-list">
                  <div v-for="(item, index) in copyData.pageNameList" :key="item.pageId">
                    <el-tag
                      type="success"
                      closable
                      @close="copyData.pageNameList.splice(index, 1)"
                    >{{ item.pageName }}</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="part">
            <span>按钮文案</span>
            <div>
              <el-radio-group v-model="copyData.bmiButtonCopy">
                <el-radio label="阅读小说"></el-radio>
                <el-radio label="关注公众号"></el-radio>
                <el-radio label="了解更多"></el-radio>
              </el-radio-group>
            </div>
          </div>
        </template>
        <template v-else-if="copyData.putWayValue === '激励式广告'">
          <div class="part">
            <span>广告样式</span>
            <div>
              <el-radio-group v-model="copyData.incentiveAdStyle">
                <el-radio label="激励视频"></el-radio>
                <el-radio label="激励浏览"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="part" v-if="copyData.incentiveAdStyle === '激励视频'">
            <span>创意样式</span>
            <div>
              <el-radio-group v-model="copyData.incentiveCreativeStyle">
                <el-radio label="横版视频"></el-radio>
                <el-radio label="竖版视频750*1334规格"></el-radio>
                <el-radio label="竖版视频720*1280规格"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="part" v-if="copyData.incentiveAdStyle === '激励视频'">
            <span style="align-self:flex-start;margin-top:10px">
              编辑视频
              <el-tooltip placement="top" content="请按照微信的标准上传视频！！！">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
            <div>
              <el-popover placement="top" trigger="hover">
                <div class="choose-upload-type">
                  <el-button type="success" class="button-small" @click="uploadFile('video')">素材库</el-button>
                  <el-button type="warning" class="button-small" @click="uploadLocal">本地上传</el-button>
                </div>
                <el-button slot="reference" plain>上传视频</el-button>
              </el-popover>
              <input
                ref="uploadVideo"
                accept="video/"
                type="file"
                style="display:none"
                @change="handleFileChange"
              />
              <div class="thumbnails-material" v-if="isValidArray(copyData.materialList)">
                <el-popover placement="right" title="原素材" trigger="hover">
                  <video controls muted autoplay :src="copyData.materialList[0].url" />
                  <img
                    slot="reference"
                    :src="`${copyData.materialList[0].url}?vframe/jpg/offset/1`"
                    alt
                  />
                </el-popover>
              </div>
            </div>
          </div>
          <div class="part" v-if="copyData.incentiveAdStyle === '激励视频'">
            <span style="align-self:flex-start;margin-top:15px">广告卡片文案 <span v-if="copyData.adCardCopyList.length > 0">({{copyData.adCardCopyList.length}})</span></span>
            <div>
              <div
                class="card-copy-list"
                v-for="(item, index) in copyData.adCardCopyList"
                :key="index"
              >
                <c-input
                  v-model="copyData.adCardCopyList[index]"
                  placeholder="广告卡片文案"
                  style="width:300px;margin:5px 0"
                ></c-input>
                <i
                  class="el-icon-circle-plus add-input input-icon"
                  @click="copyData.adCardCopyList.every(item=>!isFalsy(item)) && copyData.adCardCopyList.push('')"
                  v-if="index === copyData.adCardCopyList.length - 1"
                />
                <i
                  class="el-icon-delete-solid delete-input input-icon"
                  @click="copyData.adCardCopyList.splice(index, 1)"
                  v-if="copyData.adCardCopyList.length > 1"
                />
              </div>
            </div>
          </div>
          <div class="part" v-if="copyData.incentiveAdStyle === '激励视频'">
            <span>点击跳转</span>
            <span class="fixed-text">原生推广页</span>
          </div>
          <div class="part">
            <span style="align-self: flex-start;margin-top:10px">{{`${copyData.incentiveAdStyle === '激励视频' ? '原生推广页' : '点击跳转'}`}}</span>
            <div>
              <el-button
                v-if="!isValidArray(copyData.pageNameList)"
                plain
                @click="choosePromotionPage"
              >选择原生推广页</el-button>
              <div class="c-info" v-else>
                <div class="c-info-title">
                  <span>
                    推广页
                    <span
                      v-if="copyData.pageNameList.length > 0"
                    >({{ copyData.pageNameList.length }})</span>
                  </span>
                  <span style="font-weight: bold;" @click="choosePromotionPage">选择</span>
                </div>
                <div class="c-list">
                  <div v-for="(item, index) in copyData.pageNameList" :key="item.pageId">
                    <el-tag
                      type="success"
                      closable
                      @close="copyData.pageNameList.splice(index, 1)"
                    >{{ item.pageName }}</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="part" v-if="copyData.incentiveAdStyle === '激励视频'">
            <span>按钮文案</span>
            <div>
              <el-radio-group v-model="copyData.bmiButtonCopy">
                <el-radio label="阅读小说"></el-radio>
                <el-radio label="关注公众号"></el-radio>
                <el-radio label="了解更多"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="part" v-if="copyData.incentiveAdStyle === '激励视频'">
            <span>标签展示</span>
            <div>
              <el-radio-group v-model="copyData.bmiTagShow">
                <el-radio label="不启用"></el-radio>
                <el-tooltip content="请自行判断该账号是否有该选项!!!" placement="top">
                  <el-radio label="转化目标量标签"></el-radio>
                </el-tooltip>
                <el-tooltip content="请自行判断该账号是否有该选项!!!" placement="top">
                  <el-radio label="好友关注量标签"></el-radio>
                </el-tooltip>
              </el-radio-group>
            </div>
          </div>
        </template>
      </template>
    </div>
    <choose-template
      :show="showTemplateDialog"
      :id="`${copyData && copyData.marketing ? copyData.marketing.id : ''}`"
      :type="`${copyData && copyData.principalPart ? copyData.principalPart.id : ''}`"
      :tips="`${copyData.putPosition === '朋友圈信息流'? '' : '公众平台流量模式下无法选择“轮播图”类型的推广页！！！'}`"
      :multiSelect="true"
      :filterCallback="filterCallback"
      :excludeInfo="excludeInfo"
      @chooseSelection="chooseSelection"
      @close="showTemplateDialog = false"
      @confirm="confirmTemplate"
    ></choose-template>
    <choose-tags
      :show="showTagDialog"
      :tagsList="tagsList"
      :limitSize="limitSize"
      :limitLen="limitLen"
      :preTagsList="getPreTagsList()"
      @close="showTagDialog = false"
      @confirm="confirmTags"
    ></choose-tags>
    <el-dialog
      title="素材"
      width="1400px"
      v-dialogDrag
      top="20px"
      append-to-body
      :visible.sync="showMediaDialog"
      :before-close="handleCloseMediaDialog"
    >
      <media-library :show="showMediaDialog" :mediaType="mediaType" :maxNum="1" ref="mediaLibrary">
        <div slot="footer" style="margin-top:10px;text-align:right ;">
          <el-button type="primary" @click="() => { handleYes() }">确定</el-button>
          <el-button @click="handleCloseMediaDialog">取消</el-button>
        </div>
      </media-library>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import E from 'wangeditor'
import { uploadFile } from '@/api/common'
import { addMaterialWare } from '@/api/material'
import chooseTemplate from './../../promotion/choose-template'
import MediaLibrary from '@/views/small-plane/baidu-project/picture-library'
import chooseTags from './choose-tags'
import { emojiList } from '@/mockData/index'
import { isValidArray, _changeTimeBySecond, isValidObject, spliceMultiArrayIndex, videoStatusCheck, isFalsy } from '@/assets/js/utils'
import { getWxProjectAdLabels } from '@/api/tencent'
export default {
  props: {
    stepOneData: {
      type: Object,
      default: () => ({})

    },
    show: {
      type: Boolean,
      default: false
    },
    isEditTemplate: {
      type: Boolean,
      default: false
    },
    curInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      initializeData: {
        cardComponent: [],
        adStyle: '常规广告',
        pageNameList: [],
        materialList: [],
        textLinkCopy: '阅读小说',
        dataShow: false,
        dataType: '',
        bmCreativeStyle: '',
        bmInformationBarType: '',
        bmChooseTag: [],
        bmiTagShow: '不启用',
        bmiButtonCopy: '', // 按钮文案
        msgBoardCopyList: [''], // 信息栏文案
        guidingCopyList: [''], // 关注引导文案
        incentiveAdStyle: '', // 激励式广告样式
        incentiveCreativeStyle: '', // 激励式创意样式
        adCardCopyList: [''] // 激励式广告文案
      },
      isFalsy,
      excludeInfo: null,
      mediaType: 'image',
      curPromotionImageIndex: null, // 当前原生推广页外层图片的索引
      showMediaDialog: false,
      showTagDialog: false,
      tagsList: [], // 标签列表
      isValidArray,
      isValidObject,
      limitSize: 16,
      limitLen: 3,
      showTemplateDialog: false,
      copyData: {},
      emojiList,
      editorSuffix: 1,
      editorList: [{
        id: 'inputText-1',
        html: '', // html格式的数据
        value: '', // 去除html标签之后的数据
        emojiLen: 0, // 表情个数
        textLen: 0 // 文字长度
      }],
      editorTitleEditor: [{
        id: 'inputTitle-1',
        html: '',
        value: '',
        emojiLen: 0,
        textLen: 0
      }],
      editorInstanceList: []
    }
  },
  watch: {
    show: {
      async handler (newV) {
        if (newV === true) {
          this.setExcludeInfo()
          if (this.isEditTemplate === true) {
            this.copyData = _.cloneDeep(this.curInfo)

            if (this.copyData.putPosition === '朋友圈信息流') {
              await this.echoEditorItem()
            }
          } else {
            await this.renderData()

            this.copyData = Object.assign({}, this.curInfo, this.initializeData, _.cloneDeep(this.stepOneData))
          }
          await this.getTagsList()
        }
      },
      immediate: true
    },
    'copyData.adStyle': {
      handler (newV) {
        this.editorSuffix = 1
        for (const editor of this.editorInstanceList) {
          editor.destroy()
        }
        this.editorInstanceList = []
        this.editorList = [{
          id: 'inputText-1',
          html: '', // html格式的数据
          value: '', // 去除html标签之后的数据
          emojiLen: 0, // 表情个数
          textLen: 0 // 文字长度
        }]
        this.editorTitleEditor = [{
          id: 'inputTitle-1',
          html: '',
          value: '',
          emojiLen: 0,
          textLen: 0
        }]
        this.renderData()
        if (newV === '卡片式广告') {
          if (!isValidArray(this.copyData.cardComponent)) {
            this.$set(this.copyData, 'cardComponent', [])
          }
        }
      }
    },
    'copyData.putPosition': {
      handler (newV) {
        // 重置素材
        this.copyData.materialList = []
        this.copyData.pageNameList = []
        this.editorSuffix = 1
        for (const editor of this.editorInstanceList) {
          editor.destroy()
        }
        this.editorInstanceList = []
        this.editorList = [{
          id: 'inputText-1',
          html: '', // html格式的数据
          value: '', // 去除html标签之后的数据
          emojiLen: 0, // 表情个数
          textLen: 0 // 文字长度
        }]
        this.editorTitleEditor = [{
          id: 'inputTitle-1',
          html: '',
          value: '',
          emojiLen: 0,
          textLen: 0
        }]
        if (newV === '朋友圈信息流') {
          this.renderData()
        }
        this.setExcludeInfo()
      }
    },
    'copyData.putWayValue': {
      handler (newV) {
        // 重置素材
        this.copyData.materialList = []
        this.$set(this.copyData, 'pageNameList', [])
        if (newV === '公众号文章底部') {
          if (!this.copyData.bmCreativeStyle) {
            this.$set(this.copyData, 'bmCreativeStyle', '大图式卡片')
          }
          if (!this.copyData.bmInformationBarType) {
            this.$set(this.copyData, 'bmInformationBarType', '文案型')
          }
          if (!this.copyData.bmiTagShow) {
            this.$set(this.copyData, 'bmiTagShow', '不启用')
          }
          if (!this.copyData.bmiButtonCopy) {
            this.$set(this.copyData, 'bmiButtonCopy', '阅读小说')
          }
        }
      }
    },
    'copyData.bmCreativeStyle': {
      handler (newV) {
        if (this.copyData.putWayValue === '公众号文章底部') {
          if (newV === '图片式卡片' && ['不启用', '行业标签'].includes(this.copyData.bmiTagShow)) {
            this.copyData.bmiTagShow = '转化目标量标签'
          }
          this.copyData.materialList = []
        }
      }
    },
    'copyData.dataShow': {
      handler (newV) {
        if (!newV) {
          this.copyData.dataType = ''
        }
      }
    },
    'copyData.incentiveCreativeStyle': {
      handler (newV) {
        this.copyData.materialList = []
      }
    },
    stepOneData: {
      handler (newV) {
        this.copyData = Object.assign({}, _.cloneDeep(this.copyData), _.cloneDeep(newV))
      }
    }
  },
  methods: {
    getCurrentData () {
      // ! 朋友圈相关
      if (this.copyData.putPosition === '朋友圈信息流') {
        if (this.copyData.adStyle === '常规广告') {
          return _.cloneDeep({
            adStyle: this.copyData.adStyle,
            jumpOuterMaterial: '原生推广页',
            textLinkJump: '原生推广页',
            pageNameList: this.copyData.pageNameList,
            textLinkCopy: this.copyData.textLinkCopy,
            dataShow: this.copyData.dataShow,
            dataType: this.copyData.dataType,
            outerCopyList: this.editorList,
            cardComponent: [],
            materialList: [],
            adCardCopyList: this.checkNeedAdCardCopyList() ? this.copyData.adCardCopyList : [''], // 激励式广告文案
            guidingCopyList: this.checkNeedGuidingCopyList() ? this.copyData.guidingCopyList : [''], // 引导文案
            msgBoardCopyList: this.checkNeedMsgBoardCopyList() ? this.copyData.msgBoardCopyList : [''] // 信息栏文案
          })
        }
        if (this.copyData.adStyle === '卡片式广告') {
          return _.cloneDeep({
            adStyle: this.copyData.adStyle,
            cardComponent: this.copyData.cardComponent,
            chooseTag: this.copyData.chooseTag,
            buttonCopy: this.copyData.buttonCopy,
            buttonJump: this.copyData.buttonJump,
            outerCopyTitleList: this.editorTitleEditor,
            outerCopyList: this.editorList,
            dataShow: this.copyData.dataShow,
            dataType: this.copyData.dataType,
            pageNameList: this.copyData.pageNameList,
            materialList: this.copyData.materialList,
            adCardCopyList: this.checkNeedAdCardCopyList() ? this.copyData.adCardCopyList : [''], // 激励式广告文案
            guidingCopyList: this.checkNeedGuidingCopyList() ? this.copyData.guidingCopyList : [''], // 引导文案
            msgBoardCopyList: this.checkNeedMsgBoardCopyList() ? this.copyData.msgBoardCopyList : [''] // 信息栏文案
          })
        }
      }
      if (this.copyData.putPosition === '公众平台流量') {
        return _.cloneDeep({
          adStyle: '',
          cardComponent: [],
          incentiveAdStyle: this.copyData.incentiveAdStyle,
          incentiveCreativeStyle: this.copyData.incentiveCreativeStyle,
          adCardCopyList: this.checkNeedAdCardCopyList() ? this.copyData.adCardCopyList : [''], // 激励式广告文案
          guidingCopyList: this.checkNeedGuidingCopyList() ? this.copyData.guidingCopyList : [''], // 引导文案
          msgBoardCopyList: this.checkNeedMsgBoardCopyList() ? this.copyData.msgBoardCopyList : [''], // 信息栏文案
          bmiTagShow: this.copyData.bmiTagShow,
          bmChooseTag: this.copyData.bmChooseTag,
          materialList: this.copyData.materialList,
          pageNameList: this.copyData.pageNameList,
          bmiButtonCopy: this.copyData.bmiButtonCopy,
          bmCreativeStyle: this.copyData.bmCreativeStyle,
          bmInformationBarType: this.copyData.bmInformationBarType
        })
      }
    },
    checkNeedAdCardCopyList () {
      const { putPosition, putWayValue, incentiveAdStyle } = this.copyData
      return !!(putPosition === '公众平台流量' && putWayValue === '激励式广告' && incentiveAdStyle === '激励视频')
    },
    checkNeedGuidingCopyList () {
      const { putPosition, putWayValue, bmCreativeStyle } = this.copyData
      return !!(putPosition === '公众平台流量' && putWayValue === '公众号文章底部' && bmCreativeStyle === '名片式banner')
    },
    checkNeedMsgBoardCopyList () {
      const { putPosition, putWayValue, bmCreativeStyle, bmInformationBarType } = this.copyData
      return !!((putPosition === '公众平台流量' && putWayValue === '公众号文章底部' && bmCreativeStyle === '大图式卡片' && bmInformationBarType === '文案型') || (putPosition === '公众平台流量' && putWayValue === '公众号文章中部' && bmInformationBarType === '文案型'))
    },
    getTagsList () {
      console.log(this.copyData)
      getWxProjectAdLabels({
        subjectType: this.copyData.principalPart.type,
        accountId: this.copyData.marketing.accountId
      }).then(res => {
        this.tagsList = res
        console.log(this.tagsList)
      })
    },
    renderData () {
      // ! 初始化外层文案的编辑框
      return new Promise(resolve => {
        if (this.copyData.putPosition === '朋友圈信息流') {
          this.$nextTick(() => {
            const outerId = this.editorList[this.editorList.length - 1].id
            this.createOuterTextEditor(outerId, 'text')
            if (this.copyData.adStyle === '卡片式广告') {
              const titleId = this.editorTitleEditor[this.editorTitleEditor.length - 1].id
              this.createOuterTextEditor(titleId, 'title')
            }
          })
        }
        resolve()
      })
    },
    echoEditorItem () {
      return new Promise(resolve => {
        this.$nextTick(() => {
          const self = this
          this.editorList = this.copyData.outerCopyList
          this.$nextTick(() => {
            echoCreateEditor(this.copyData.outerCopyList, 'text', this.emojiList)
            if (this.copyData.adStyle === '卡片式广告') {
              this.editorTitleEditor = this.copyData.outerCopyTitleList
              this.$nextTick(() => {
                echoCreateEditor(this.copyData.outerCopyTitleList, 'title', this.emojiList)
              })
            }
          })
          this.editorSuffix = this.copyData.outerCopyList.length + this.copyData.outerCopyTitleList ? this.copyData.outerCopyList.length : 0 + 1
          function echoCreateEditor (data, type, emojiArr) {
            for (let i = 0; i < data.length; i++) {
              const obj = data[i]
              const editor = new E(`#${obj.id}`)
              editor.config.menus = ['emoticon']
              editor.config.height = type === 'text' ? 100 : 45
              editor.config.zIndex = 1
              editor.config.showFullScreen = false
              editor.config.onchange = html => {
                self.editorChange(html, obj.id, type)
              }
              editor.config.placeholder = type === 'text'
                ? '文案友好、易阅读和理解，建议与图片设计、详情页内容关联'
                : '选填广告标题'
              // !初始化表情包
              const emojiList = emojiArr.map(item => {
                return {
                  alt: item.parentName,
                  src: item.name
                }
              })
              editor.config.emotions = [
                {
                  title: '默认',
                  type: 'image',
                  content: emojiList
                }
              ]
              editor.create()
              editor.txt.html(obj.html)
              self.editorInstanceList.push(editor)
            }
          }
          return resolve()
        })
      })
    },
    chooseTags (limitSize = null, limitLen = null) {
      if (limitSize) this.limitSize = limitSize
      if (limitLen) this.limitLen = limitLen
      console.log(this.limitSize)
      this.showTagDialog = true
    },
    createOuterTextEditor (id, type) {
      const editor = new E(`#${id}`)
      editor.config.menus = ['emoticon']
      editor.config.height = type === 'text' ? 100 : 45
      editor.config.zIndex = 1
      editor.config.showFullScreen = false
      editor.config.onchange = (html) => {
        this.editorChange(html, id, type)
      }
      if (this.copyData.putPosition === '朋友圈信息流') {
        editor.config.placeholder = this.copyData.adStyle === '常规广告' ? '文案友好、易阅读和理解，建议与图片设计、详情页内容关联' : '选填广告标题'
      }
      // !初始化表情包
      const emojiList = this.emojiList.map((item) => {
        return {
          alt: item.parentName,
          src: item.name
        }
      })
      editor.config.emotions = [
        {
          title: '默认',
          type: 'image',
          content: emojiList
        }
      ]
      editor.create()
      this.editorInstanceList.push(editor)
    },
    editorChange (html, id, type) {
      const dataList = type === 'text' ? this.editorList : this.editorTitleEditor
      const maxEmojiLen = type === 'text' ? 4 : 1
      for (const editor of dataList) {
        if (editor.id === id) {
          const textValue = html.replace(/<\/?.+?>/g, '')
          if (/(^&nbsp;)|(&nbsp;$)/g.test(textValue)) {
            this.$message.error('广告文案行首或行尾不能包含空格，请删除!')
          }
          const ChineseLen =
            (textValue.match(/[\u4e00-\u9fa5]/g)
              ? textValue.match(/[\u4e00-\u9fa5]/g).length
              : null) || 0
          // 中文标点符号需要算一个字符
          const isContainsChanesePunctuation = textValue.match(
            /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g
          )
          const ChinesePunctuationLen =
            (isContainsChanesePunctuation
              ? isContainsChanesePunctuation.length
              : null) || 0
          const WordLen = Math.ceil(
            (textValue.length - ChineseLen - ChinesePunctuationLen) / 2
          )
          const imgRegExp = /<img[^>]+>/g
          const emojiArr = html.match(imgRegExp)
          const eLen = emojiArr ? emojiArr.length : 0
          const textLen = ChineseLen + WordLen + eLen + ChinesePunctuationLen
          if (emojiArr && emojiArr.length > maxEmojiLen) {
            this.$message.error(`最多选择${maxEmojiLen}个表情!`)
          }
          editor.html = html
          editor.textLen = textLen
          editor.emojiLen = eLen
          editor.value = this.formateRichText(html)
          return
        }
      }
    },
    addEditor (type) {
      // todo=>区分外城文案和外层标题文案
      this.editorSuffix++
      if (this.copyData.adStyle === '常规广告') {
        const id = type === 'text' ? `inputText-${this.editorSuffix}` : `inputTitle-${this.editorSuffix}`
        if (type === 'text') {
          this.editorList.push({
            id,
            html: '',
            value: '',
            emojiLen: 0,
            textLen: 0
          })
        } else {
          this.editorTitleEditor.push({
            id,
            html: '',
            value: '',
            emojiLen: 0,
            textLen: 0
          })
        }
        this.$nextTick(() => {
          this.createOuterTextEditor(id, type)
        })
      } else {
        const textId = `inputText-${this.editorSuffix}`
        const titleId = `inputTitle-${this.editorSuffix}`
        this.editorList.push({
          id: textId,
          html: '',
          value: '',
          emojiLen: 0,
          textLen: 0
        })
        this.editorTitleEditor.push({
          id: titleId,
          html: '',
          value: '',
          emojiLen: 0,
          textLen: 0
        })
        this.$nextTick(() => {
          this.createOuterTextEditor(textId, 'text')
          this.createOuterTextEditor(titleId, 'title')
        })
      }
    },
    formateRichText (html) {
      return html.replace(/<\/?[p|span].*?>/gmi, '') // 去除span 和p 标签
        .replace(/<img.*?alt=(['|"])\[(.*?)\]\1\/>/gmi, '$2') // 提取 img alt属性里面的内容
        .replace(/<br\/>/gmi, '') // 取消<br\>换行标签
    },
    closeEditor (index) {
      if (this.editorList.length === 1) {
        this.showErrorMsg('至少保留一个广告文案!')
        return
      }
      this.editorList.splice(index, 1)
    },
    choosePromotionPage () {
      this.showTemplateDialog = true
    },
    removeEditor (type, index, item) {
      if (this.copyData.adStyle === '常规广告') {
        if (type === 'text') {
          if (this.editorList.length === 1) return this.$message.warning('至少保留一个广告文案!')
          this.editorList.splice(index, 1)
        } else {
          if (this.editorTitleEditor.length === 1) return this.$message.warning('至少保留一个广告文案标题!')
          this.editorTitleEditor.splice(index, 1)
        }
        const targetEditorIndex = this.editorInstanceList.findIndex(item => item.toolbarSelector.slice(1) === item.id)
        if (targetEditorIndex > -1) {
          this.editorInstanceList.splice(targetEditorIndex, 1)
          this.editorInstanceList[targetEditorIndex].destroy()
        }
      } else {
        const textId = this.editorList[index].id
        const titleId = this.editorTitleEditor[index].id
        this.editorList.splice(index, 1)
        this.editorTitleEditor.splice(index, 1)
        const targetEditorIndex = this.editorInstanceList.findIndex(item => item.toolbarSelector.slice(1) === textId)
        const targetEditorTitleIndex = this.editorInstanceList.findIndex(item => item.toolbarSelector.slice(1) === titleId)
        this.editorInstanceList[targetEditorIndex].destroy()
        this.editorInstanceList[targetEditorTitleIndex].destroy()
        this.editorInstanceList = spliceMultiArrayIndex(this.editorInstanceList, [targetEditorIndex, targetEditorTitleIndex])
      }
    },
    confirmTags (data) {
      if (this.copyData.putPosition === '朋友圈信息流') {
        this.$set(this.copyData, 'chooseTag', data)
      }

      if (this.copyData.putPosition === '公众平台流量') {
        if (['公众号文章底部', '公众号文章中部'].includes(this.copyData.putWayValue)) {
          this.$set(this.copyData, 'bmChooseTag', data)
        }
      }
    },
    confirmTemplate (data) {
      this.copyData.pageNameList.push(data)
    },
    chooseSelection (data) {
      this.copyData.pageNameList = [...this.copyData.pageNameList, ...data]
    },
    handleYes () {
      this.showMediaDialog = false
      const resourceList = this.$refs.mediaLibrary.imageList.slice(0, 1)
      const [f] = resourceList
      const { resources } = f
      this.$set(this.copyData.materialList, this.curPromotionImageIndex, {
        title: resources[0].title,
        url: resources[0].url,
        id: resources[0].materialId,
        type: resources[0].materialType === 1 ? 2 : 1
      })
    },
    chooseOuterPic (targetValue, index) {
      if (targetValue.page_elements_spec_list[0].elementType === 'VIDEO') {
        this.mediaType = 'video'
      } else {
        this.mediaType = 'image'
      }
      this.curPromotionImageIndex = index
      this.showMediaDialog = true
    },
    handleCloseMediaDialog () {
      this.showMediaDialog = false
    },
    handleRemovePageItem (index) {
      this.copyData.pageNameList.splice(index, 1)
      this.copyData.materialList[index] && this.copyData.materialList.splice(index, 1)
    },
    filterCallback (value) {
      return value.page_elements_spec_list.some(value => value.elementType === 'ELEMENT_TYPE_UNSUPPORTED')
    },
    uploadFile (type) {
      this.mediaType = type
      this.curPromotionImageIndex = 0
      this.showMediaDialog = true
    },
    uploadLocal () {
      this.$refs.uploadVideo.click()
    },
    async handleFileChange (e) {
      const targetFile = e.target.files[0]
      const { file, ...rest } = await videoStatusCheck(targetFile, 100, { minTime: 6, maxTime: 60 }, { width: null, height: null })
      this.$refs.uploadVideo.value = null
      if (!file) {
        return this.$message.warning(rest.message)
      }

      try {
        const res = await uploadFile({ file })
        const params = {
          materialType: 1,
          title: file.name.replace(/\.\w+/g, ''),
          materialResources: {
            title: file.name.replace(/\.\w+/g, ''),
            url: res,
            widthAndHigh: `${rest.width} * ${rest.height}`,
            videoTime: _changeTimeBySecond(+(rest.duration)),
            size: this.getSize(file.size),
            format: this.getType(file.type),
            materialId: null,
            sort: 1
          }
        }

        const [f] = await addMaterialWare([params])
        const { resources } = f
        this.$set(this.copyData.materialList, 0, {
          title: resources[0].title,
          url: resources[0].url,
          id: resources[0].materialId,
          type: 2
        })
      } catch (e) {
        this.$message.error('上传视频失败，请稍后再试!')
      }
    },
    getPreTagsList () {
      if (this.copyData.putPosition === '朋友圈信息流') {
        return isValidArray(this.copyData.chooseTag) ? this.copyData.chooseTag : []
      }
      if (this.copyData.putPosition === '公众平台流量') {
        if (this.copyData.putWayValue === '公众号文章底部') {
          return isValidArray(this.copyData.bmChooseTag) ? this.copyData.bmChooseTag : []
        }
      }
      return []
    },
    getSize (size) {
      if (size / 1024 / 1024 < 1) {
        return `${Math.floor(size / 1024)}KB`
      } else {
        return `${Math.floor(size / 1024 / 1024)}MB`
      }
    },
    getType (val) {
      const type = val.split(/\//g)
      return type[type.length - 1]
    },
    setExcludeInfo () {
      this.excludeInfo = this.copyData.putPosition === '朋友圈信息流' ? [] : ['CAROUSEL']
    },
    resetData (property = null) {
      this.editorSuffix = 1
      for (const editor of this.editorInstanceList) {
        editor.destroy()
      }
      this.editorInstanceList = []
      this.editorList = [{
        id: 'inputText-1',
        html: '', // html格式的数据
        value: '', // 去除html标签之后的数据
        emojiLen: 0, // 表情个数
        textLen: 0 // 文字长度
      }]
      this.editorTitleEditor = [{
        id: 'inputTitle-1',
        html: '',
        value: '',
        emojiLen: 0,
        textLen: 0
      }]
      this.initializeData = {
        cardComponent: [],
        adStyle: '常规广告',
        pageNameList: [],
        materialList: [],
        textLinkCopy: '阅读小说',
        dataShow: false,
        dataType: '',
        bmCreativeStyle: '',
        bmInformationBarType: '',
        bmChooseTag: [],
        bmiTagShow: '不启用',
        bmiButtonCopy: '', // 按钮文案
        msgBoardCopyList: [''], // 信息栏文案
        guidingCopyList: [''], // 关注引导文案
        incentiveAdStyle: '', // 激励式广告样式
        incentiveCreativeStyle: '', // 激励式创意样式
        adCardCopyList: [''] // 激励式广告文案
      }
      this.excludeInfo = [] 
    }

  },
  components: { chooseTemplate, chooseTags, MediaLibrary }
}
</script>
<style lang='scss' scoped>
/deep/ .eleImg {
  width: 24px !important;
  height: 20px !important;
  vertical-align: middle;
}
/deep/ .w-e-panel-tab-content {
  height: 68px !important;
}
.fixed-text {
  color: $main;
  font-weight: bold;
}
.part {
  @include flex(flex-start, center);
  margin: 30px 0;
  > span:nth-of-type(1) {
    width: 200px;
    flex-shrink: 0;
    text-align: right;
    margin-right: 20px;
  }
}
.outer-text-editor-wrapper {
  flex-grow: 1;
  @include flex(flex-start, flex-center, row, wrap);
  .editor-container {
    position: relative;
    margin-bottom: 20px;
    .editor {
      margin: 0 20px 5px 0;
      width: 300px;
    }
    .el-icon-circle-plus,
    .el-icon-delete-solid {
      position: absolute;
      display: none;
    }
    &:hover {
      .el-icon-circle-plus {
        font-size: 24px;
        display: block;
        top: 5%;
        left: 80%;
        color: $main;
        z-index: 999;
        cursor: pointer;
      }
      .el-icon-delete-solid {
        font-size: 18px;
        display: block;
        top: -4%;
        cursor: pointer;
        left: 90%;
        color: $colorRed;
        z-index: 999;
      }
    }
  }
}
.c-info {
  border: 1px solid #ececec;
  border-radius: 8px;
  padding: 10px;
  &-title {
    @include flex(space-between, center);
    margin-bottom: 10px;
  }
  .c-list {
    @include flex(flex-start, flex-start, column);
    > div {
      margin: 0 10px 10px 0;
    }
    /deep/ .el-tag {
      @include flex(space-between, center);
    }
  }
}
.showWarning {
  box-shadow: 0px 0px 1px 2px #fc876a;
}
.original-image {
  img {
    width: 150px;
    height: 150px;
    background: #f5f5f5;
    border-radius: 6px;
    object-fit: contain;
  }
  video {
    width: 200px;
    height: 200px;
    background: #f5f5f5;
    border-radius: 6px;
    object-fit: contain;
  }
}
.ad-brand-image,
.thumbnails-material {
  margin-top: 10px;
  img {
    width: 100px;
    height: 100px;
    background: #ccc;
    border-radius: 6px;
    object-fit: contain;
  }
  video {
    width: 200px;
    height: 150px;
    object-fit: contains;
  }
}
.card-copy-list {
  @include flex(flex-start, center);
  .input-icon {
    cursor: pointer;
    font-size: 20px;
    display: none;
  }
  .add-input {
    color: $main;
    margin-left: 5px;
  }
  .delete-input {
    color: $colorRed;
    margin-left: 5px;
  }
  &:hover {
    .add-input,
    .delete-input {
      display: block;
    }
  }
}
</style>

<template>
  <div class="plan-container">
    <dy-table
      :dataList="dataList"
      :rowList="rowList"
      height="75vh"
      :showPagination="true"
      :isUseCheckbox="true"
      :pageSize="pageSize"
      :loading="loading"
      :page="page"
      :total="total"
      @pageChange="handlePage"
    >
      <template #filter>
        <el-button @click="initData" style="margin-right:10px">刷新数据</el-button>
        <el-button type="success" style="margin-right:10px" @click="addContent">新增</el-button>
        <c-input v-model="templateName" style="width:300px;margin-right:10px" placeholder="模板名称">
          <el-button slot="append" icon="el-icon-search" @click="handlePage(1)"></el-button>
        </c-input>
        <el-button type="text" @click="$router.push({name:'Promotion'})">跳转推广页</el-button>
      </template>
      <template #createdTime="{ row }">
        <span>{{ formatTime(row.createdTime, 'yyyy-MM-dd hh:mm:ss') }}</span>
      </template>
      <template #status="{ row }">
        <el-tag :type="getTagInfo(row.status)">{{ row.status }}</el-tag>
      </template>
      <template #operation="{ row }">
        <c-button class="button-small" type="info" @click="(cb) => { checkDetail(row.id, cb) }">详情</c-button>
        <c-button class="button-small" type="primary" @click="(cb) => { editTemplate(row, cb) }">编辑</c-button>
        <c-button
          class="button-small"
          type="danger"
          :autoConfirm="true"
          @confirm="(cb) => { handleDelete(row, cb) }"
        >删除</c-button>
      </template>
    </dy-table>
    <add-plan-template
      @refreshData="handlePage(1)"
      @close="showAddDialog = false"
      :curTemplateId="templateId"
      :isEdit="isEdit"
      :show="showAddDialog"
      :curTemplateInfo="curTemplateInfo"
    ></add-plan-template>
    <plan-detail :show="showDetailDialog" @close="showDetailDialog=false" :id="detailTemplateId"></plan-detail>
  </div>
</template>

<script>
import { getWxPlanList, delWxPlan, getWxEditPlanData } from '@/api/tencent'
import { formatTime } from '@/assets/js/utils'
import { mapGetters } from 'vuex'
import addPlanTemplate from './add-plan.vue'
import planDetail from './plan-detail.vue'
export default {
  data () {
    return {
      page: 1,
      total: 0,
      search: '',
      status: '',
      formatTime,
      dataList: [],
      pageSize: 15,
      templateId: 0,
      isEdit: false,
      loading: false,
      templateName: '',
      detailTemplateId: 0, // 模板详情Id
      curTemplateInfo: {},
      showAddDialog: false,
      showDetailDialog: false,
      rowList: [{
        label: 'id',
        prop: 'id',
        colWidth: '80'
      }, {
        label: '模板名称',
        prop: 'templateName'
      }, {
        label: '状态',
        prop: 'status',
        slot: true
      }, {
        label: '创建时间',
        prop: 'createdTime',
        slot: true
      }, {
        label: '操作',
        action: 'operation'

      }]
    }
  },
  computed: {
    ...mapGetters(['userList'])
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      getWxPlanList({
        page: this.page,
        pageSize: this.pageSize,
        templateName: this.templateName
      }).then(res => {
        this.dataList = res.list
        this.total = res.total
      })
    },
    addContent () {
      this.curTemplateInfo = {}
      this.templateId = ''
      this.isEdit = false
      this.showAddDialog = true
    },
    getTagInfo (status) {
      if (status === '全部未上传') {
        return 'info'
      }
      if (status === '全部上传') {
        return 'success'
      }
      if (status === '部分未上传') {
        return 'warning'
      }
      return 'info'
    },
    handlePage (page) {
      this.page = page
      this.initData()
    },
    checkDetail (id, cb) {
      cb()
      this.detailTemplateId = id
      this.showDetailDialog = true
    },
    editTemplate (row, cb) {
      getWxEditPlanData({
        templateId: row.id
      }).then(res => {
        this.isEdit = true
        this.curTemplateInfo = res
        this.showAddDialog = true
        this.templateId = row.id
      })
      cb()
    },
    handleDelete (row, cb) {
      delWxPlan(row.id).then(() => {
        this.$message.success('删除成功!')
        this.initData()
      }).finally(() => {
        cb()
      })
    }

  },
  components: { addPlanTemplate, planDetail }
}
</script>
<style lang='scss' scoped>
</style>

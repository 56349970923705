<template>
    <el-dialog
        title="选择标签"
        append-to-body
        width="1000px"
        top="20px"
        :visible.sync="show"
        :close-on-click-modal="false"
        @close="handleClose"
    >
        <div class="tags-container">
            <div class="tag-category" v-for="(item, i) in tagsList" :key="i">
                <span>{{ item.label_category }}</span>
                <div class="tag-category-list">
                    <div
                        class="tag-category-item"
                        @click="pushTag(tag)"
                        :class="{ isChoose: chooseTagsList.includes(tag) }"
                        v-for="(tag, index) in item.label"
                        :key="index"
                    >{{ tag }}</div>
                </div>
            </div>
        </div>
        <div class="tag-info">
            <div :class="{ isWarning: checkIsWarning() }">
                <span>标签数：</span>
                <span style="font-weight:bold">{{ chooseTagsList.length }} / {{limitLen}}</span>
            </div>
            <div :class="{ isWarning: checkIsWarning() }">
                <span>总字数：</span>
                <span style="font-weight:bold">{{ getTotalTextSize() }} / {{limitSize}}</span>
            </div>
            <p style="margin-top:20px">
                <span v-if="chooseTagsList.length === 0">请选择或自定义标签</span>
                <el-tag
                    v-else
                    style="margin-right:20px"
                    type="success"
                    @close="chooseTagsList.splice(index, 1)"
                    closable
                    v-for="(tag, index) in  chooseTagsList"
                    :key="index"
                >{{ tag }}</el-tag>
            </p>
        </div>
        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import _ from 'lodash'
import { isValidArray } from '@/assets/js/utils'
export default {
  props: {
    tagsList: {
      type: Array,
      default: () => ({})
    },
    preTagsList: {
      type: Array,
      default: () => ({})
    },
    limitSize:{
      type:Number,
      default:16
    },
    limitLen:{
      type:Number,
      default:3
    },
    show: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      chooseTagsList: []
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV === true) {
          this.chooseTagsList = isValidArray(this.preTagsList) ? _.cloneDeep(this.preTagsList) : []
        }
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    getTotalTextSize () {
      return this.chooseTagsList.reduce((acc, b) => {
        return acc + b.length
      }, 0)
    },
    checkIsWarning () {
      return this.chooseTagsList.length > this.limitLen || this.getTotalTextSize() > this.limitSize
    },
    handleConfirm () {
      if (this.chooseTagsList.length === 0) {
        return this.$message.warning('标签不能为空!')
      }
      if (this.chooseTagsList.length > this.limitLen) {
        return this.$message.warning(`标签不能多于${this.limitLen}个`)
      }
      if (this.getTotalTextSize() > this.limitSize) {
        return this.$message.error('标签总字数过长!')
      }
      this.$emit('confirm', this.chooseTagsList)
      this.$emit('close')
    },
    pushTag (val) {
      if (this.chooseTagsList.includes(val)) {
        const index = this.chooseTagsList.findIndex(tag => tag === val)
        this.chooseTagsList.splice(index, 1)
      } else {
        this.chooseTagsList.push(val)
      }
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
/deep/ .el-dialog__body {
    padding-bottom: 0;
}
.tags-container {
    max-height: 500px;
    overflow: auto;
}
.tag-category {
    padding:20px 0;
    border-bottom: 1px solid #f2f2f2;
    @include flex(flex-start, flex-start);

    > span {
        width: 8%;
        flex-shrink: 0;
        text-align: right;
        margin-top: 8px;
    }
    .tag-category-list {
        margin-left: 20px;
        @include flex(flex-start, flex-start, row, wrap) .tag-category-item {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            margin: 0 12px 12px 0;
            cursor: pointer;
            &:hover {
                background-color: #f1f1f1;
            }
        }
        .isChoose {
            color: $main;
            border-color: $main;
        }
    }
}
.tag-info {
    border-top: 1px solid #f2f2f2;
    margin: 20px 20px 0 0;
    > div {
        display: inline-block;
        // padding-top: 20px;
        padding:20px 20px 0 0;
    }
}
.isWarning {
    color: $colorRed;
}
</style>
